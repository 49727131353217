const stateDebt = {
  debtDynamicsRecentArm: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_quarter_last_years/',
    params: { region: 'Армения' },
  },
  debtDynamicsYearlyArm: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_previous_years/',
    params: { region: 'Армения' },
  },
  debtDynamicsRecentBel: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_quarter_last_years/',
    params: { region: 'Беларусь' },
  },
  debtDynamicsYearlyBel: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_previous_years/',
    params: { region: 'Беларусь' },
  },
  debtDynamicsRecentKaz: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_quarter_last_years/',
    params: { region: 'Казахстан' },
  },
  debtDynamicsYearlyKaz: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_previous_years/',
    params: { region: 'Казахстан' },
  },
  debtDynamicsRecentKyr: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_quarter_last_years/',
    params: { region: 'Кыргызстан' },
  },
  debtDynamicsYearlyKyr: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_previous_years/',
    params: { region: 'Кыргызстан' },
  },
  debtDynamicsRecentRus: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_quarter_last_years/',
    params: { region: 'Россия' },
  },
  debtDynamicsYearlyRus: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_to_GDP/by_previous_years/',
    params: { region: 'Россия' },
  },
  debtStructureRecentArm: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_quarter_last_years/',
    params: { region: 'Армения' },
  },
  debtStructureYearlyArm: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_previous_years/',
    params: { region: 'Армения' },
  },
  debtStructureRecentBel: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_quarter_last_years/',
    params: { region: 'Беларусь' },
  },
  debtStructureYearlyBel: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_previous_years/',
    params: { region: 'Беларусь' },
  },
  debtStructureRecentKaz: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_quarter_last_years/',
    params: { region: 'Казахстан' },
  },
  debtStructureYearlyKaz: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_previous_years/',
    params: { region: 'Казахстан' },
  },
  debtStructureRecentKyr: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_quarter_last_years/',
    params: { region: 'Кыргызстан' },
  },
  debtStructureYearlyKyr: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_previous_years/',
    params: { region: 'Кыргызстан' },
  },
  debtStructureRecentRus: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_quarter_last_years/',
    params: { region: 'Россия' },
  },
  debtStructureYearlyRus: {
    method: 'GET',
    url: 'EAEU/public_debt/volume_structure/by_previous_years/',
    params: { region: 'Россия' },
  },
  budgetRecentArm: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_quarter_last_years/',
    params: { region: 'Армения' },
  },
  budgetYearlyArm: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_previous_years/',
    params: { region: 'Армения' },
  },
  budgetRecentBel: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_quarter_last_years/',
    params: { region: 'Беларусь' },
  },
  budgetYearlyBel: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_previous_years/',
    params: { region: 'Беларусь' },
  },
  budgetRecentKaz: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_quarter_last_years/',
    params: { region: 'Казахстан' },
  },
  budgetYearlyKaz: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_previous_years/',
    params: { region: 'Казахстан' },
  },
  budgetRecentKyr: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_quarter_last_years/',
    params: { region: 'Кыргызстан' },
  },
  budgetYearlyKyr: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_previous_years/',
    params: { region: 'Кыргызстан' },
  },
  budgetRecentRus: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_quarter_last_years/',
    params: { region: 'Россия' },
  },
  budgetYearlyRus: {
    method: 'GET',
    url: 'EAEU/state_budget/volume_structure_proficit_to_GDP_by_previous_years/',
    params: { region: 'Россия' },
  },
};

export default stateDebt;
