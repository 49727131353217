const migrationFlows = {
  migrationGrowthByQuarterLastYear: {
    method: 'GET',
    url: 'EAEU/migration/growth_by_quarter_last_year/',
  },
  migrationVolumeByYear: {
    method: 'GET',
    url: 'EAEU/migration/volume_by_year/',
  },
};

export default migrationFlows;
