import CountryButtons from 'components/CountryButtons';
import DynamicChart from 'components/DynamicChart';
import { countries } from 'configs/countries';
import { useContext } from 'react';
import Context from 'context/CountryContext';
import { ChartConfigs } from 'configs/pages/SelectedCountries/TradeInServices';
import styles from '../Page.module.scss';

const TradeInServicesSC = () => {
  const { currentCountry } = useContext(Context);

  const countrySet = ['Russia', 'Belarus', 'Armenia', 'Kyrgyzstan', 'Kazakhstan'];
  const countrySetPlusUkr = ['Russia', 'Belarus', 'Armenia', 'Kyrgyzstan', 'Kazakhstan', 'Ukraine'];

  const countrySetSecond = [
    'Azerbaijan',
    'Georgia',
    'Moldova',
    'Tajikistan',
    'Uzbekistan',
    'Turkmenistan',
    'Ukraine',
  ];
  // const countrySetTradeDetailedExportImport = [
  //   'Azerbaijan',
  //   'Georgia',
  //   'Moldova',
  //   'Tajikistan',
  //   'Uzbekistan',
  // ];

  const countrySetDynamicsGrowthWithWorld = [
    'Georgia',
    'Moldova',
    'Tajikistan',
    'Uzbekistan',
    'Ukraine',
  ];

  return (
    <>
      <h1>Торговля услугами</h1>

      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
          countries.Azerbaijan,
          countries.Georgia,
          countries.Moldova,
          countries.Tajikistan,
          countries.Turkmenistan,
          countries.Uzbekistan,
          countries.Ukraine,
          // countries.Abkhazia,
          // countries.SouthOssetia,
        ]}
      />

      {/* Общие графики для России, Беларусь, Армения, Кыргызстан, Казахстан */}

      {countrySet.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.Dynamics[currentCountry]} />
        </div>
      )}

      {countrySet.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.TradeOfServices[currentCountry]} />
        </div>
      )}

      {/* Общие графики для Азербайджан, Грузия, Молдова, Таджикистан, Узбекистан, Туркменистан */}

      {countrySetSecond.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.DynamicsGrowthWithRus[currentCountry]} />
        </div>
      )}

      {/* --------------------- */}

      {/* Графики Азербайджан */}

      {currentCountry === 'Azerbaijan' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.TradeVolumeGrowthByQuarterAze} />
        </div>
      )}

      {/* --------------------- */}

      {/* Общие графики для Грузия, Молдова, Таджикистан, Узбекистан, Украина */}

      {countrySetDynamicsGrowthWithWorld.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.DynamicsGrowthWithWorld[currentCountry]} />
        </div>
      )}

      {/* --------------------- */}

      {countrySetPlusUkr.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.TradeDetailed[currentCountry]} />
        </div>
      )}

      {/* {countrySetTradeDetailedExportImport.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.TradeDetailedExportImport[currentCountry]} />
        </div>
      )} */}

      {/* {countrySetSecond.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.TradeDetailedWithRus[currentCountry]} />
        </div>
      )} */}
    </>
  );
};

export default TradeInServicesSC;
