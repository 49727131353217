const GDP = {
  volumeByYearExchangeRate: {
    method: 'GET',
    url: 'GDP/volume/by_year_exchange_rate/',
  },
  GDPByActEAEU: {
    method: 'GET',
    url: 'GDP/share/by_activity_previous_years/',
    params: { region: 'ЕАЭС' },
  },
  GDPByActArm: {
    method: 'GET',
    url: 'GDP/share/by_activity_previous_years/',
    params: { region: 'Армения' },
  },
  GDPByActBel: {
    method: 'GET',
    url: 'GDP/share/by_activity_previous_years/',
    params: { region: 'Беларусь' },
  },
  GDPByActKaz: {
    method: 'GET',
    url: 'GDP/share/by_activity_previous_years/',
    params: { region: 'Казахстан' },
  },
  GDPByActKyr: {
    method: 'GET',
    url: 'GDP/share/by_activity_previous_years/',
    params: { region: 'Кыргызстан' },
  },
  GDPByActRus: {
    method: 'GET',
    url: 'GDP/share/by_activity_previous_years/',
    params: { region: 'Россия' },
  },
  GDPByActLastEAEU: {
    method: 'GET',
    url: 'GDP/volume/by_activity_last_year/',
    params: { region: 'ЕАЭС' },
  },
  GDPByActLastArm: {
    method: 'GET',
    url: 'GDP/volume/by_activity_last_year/',
    params: { region: 'Армения' },
  },
  GDPByActLastBel: {
    method: 'GET',
    url: 'GDP/volume/by_activity_last_year/',
    params: { region: 'Беларусь' },
  },
  GDPByActLastKaz: {
    method: 'GET',
    url: 'GDP/volume/by_activity_last_year/',
    params: { region: 'Казахстан' },
  },
  GDPByActLastKyr: {
    method: 'GET',
    url: 'GDP/volume/by_activity_last_year/',
    params: { region: 'Кыргызстан' },
  },
  GDPByActLastRus: {
    method: 'GET',
    url: 'GDP/volume/by_activity_last_year/',
    params: { region: 'Россия' },
  },
  GDPvolumeByYearEAEU: {
    method: 'GET',
    url: 'GDP/volume/by_previous_years/',
    params: { region: 'ЕАЭС' },
  },
  GDPvolumeByQuarterEAEU: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'ЕАЭС' },
  },
  GDPvolumeByQuarterArm: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Армения' },
  },
  GDPvolumeByQuarterBel: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Беларусь' },
  },
  GDPvolumeByQuarterKaz: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Казахстан' },
  },
  GDPvolumeByQuarterKyr: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Кыргызстан' },
  },
  GDPvolumeByQuarterRus: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Россия' },
  },

  GDPByActLastFreqEAEU: {
    method: 'GET',
    url: 'GDP/volume/by_previous_years/',
    params: { region: 'ЕАЭС' },
  },
  GDPvolumeByQuarterFreqRus: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Россия' },
  },
  GDPByActLastFreqRus: {
    method: 'GET',
    url: 'GDP/volume/by_previous_years/',
    params: { region: 'Россия' },
  },
  GDPvolumeByQuarterFreqBel: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Беларусь' },
  },
  GDPByActLastFreqBel: {
    method: 'GET',
    url: 'GDP/volume/by_previous_years/',
    params: { region: 'Беларусь' },
  },
  GDPvolumeByQuarterFreqArm: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Армения' },
  },
  GDPByActLastFreqArm: {
    method: 'GET',
    url: 'GDP/volume/by_previous_years/',
    params: { region: 'Армения' },
  },
  GDPvolumeByQuarterFreqKyr: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Кыргызстан' },
  },
  GDPByActLastFreqKyr: {
    method: 'GET',
    url: 'GDP/volume/by_previous_years/',
    params: { region: 'Кыргызстан' },
  },
  GDPvolumeByQuarterFreqKaz: {
    method: 'GET',
    url: 'GDP/volume/by_quarter_last_year/',
    params: { region: 'Казахстан' },
  },
  GDPByActLastFreqKaz: {
    method: 'GET',
    url: 'GDP/volume/by_previous_years/',
    params: { region: 'Казахстан' },
  },
  GDPvolumeByQuarterFreqAze: {
    method: 'GET',
    url: 'Azerbaijan/GDP/volume_growth_by_quarter_last_years/',
  },

  GDPvolumeByQuarterFreqMol: {
    method: 'GET',
    url: 'Moldova/GDP/volume_growth_by_quarter_last_years/',
  },
  GDPByActLastFreqAze: {
    method: 'GET',
    url: 'Azerbaijan/GDP/volume_growth_by_previous_years/',
  },

  DynamicsOfGDP: {
    method: 'GET',
    url: 'GDP/growth/by_year_EAEU/',
  },
  GDPByActMol: {
    method: 'GET',
    url: 'Moldova/GDP/share_by_activity_previous_years/',
  },
  GDPGrowthArm: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Армения' },
  },
  GDPGrowthBel: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Беларусь' },
  },
  GDPGrowthKaz: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Казахстан' },
  },
  GDPGrowthKyr: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Кыргызстан' },
  },
  GDPGrowthRus: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Россия' },
  },
  GDPGrowthAze: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Азербайджан' },
  },
  GDPGrowthMol: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Молдова' },
  },
  GDPGrowthTaj: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Таджикистан' },
  },
  GDPGrowthUzb: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Узбекистан' },
  },

  GDPGrowthUkr: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Украина' },
  },
  GDPGrowthTyr: {
    method: 'GET',
    url: 'GDP/growth/by_year/',
    params: { region: 'Туркмения' },
  },
  growthEAEU: {
    method: 'GET',
    url: 'hardcode/GDP/integration_effect_economy/',
    params: { region: 'ЕАЭС' },
  },
  growthArm: {
    method: 'GET',
    url: 'hardcode/GDP/integration_effect_economy/',
    params: { region: 'Армения' },
  },
  growthBel: {
    method: 'GET',
    url: 'hardcode/GDP/integration_effect_economy/',
    params: { region: 'Беларусь' },
  },
  growthKaz: {
    method: 'GET',
    url: 'hardcode/GDP/integration_effect_economy/',
    params: { region: 'Казахстан' },
  },
  growthKyr: {
    method: 'GET',
    url: 'hardcode/GDP/integration_effect_economy/',
    params: { region: 'Кыргызстан' },
  },
  growthRus: {
    method: 'GET',
    url: 'hardcode/GDP/integration_effect_economy/',
    params: { region: 'Россия' },
  },
  mutualTrade: {
    method: 'GET',
    url: 'GDP/growth/by_year_integration_effect_trade/',
  },
  YearlyProductionMol: {
    method: 'GET',
    url: 'Moldova/macro/yearly_production/',
  },
  YearlyProductionGeo: {
    method: 'GET',
    url: 'Georgia/macro/yearly_production/',
  },
  GDPdynamicsKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/macro/gdp_yearly/',
  },
  GDPdynamicsBel: {
    method: 'GET',
    url: 'Belarus/macro/gdp_yearly/',
  },
  GDPByYearMol: {
    method: 'GET',
    url: 'Moldova/GDP/yearly/',
  },
  GDPByQuarterMol: {
    method: 'GET',
    url: 'Moldova/macro/gdp_quarterly/',
  },
  GDPByQuarterUkr: {
    method: 'GET',
    url: 'Ukraine/macro/gdp_quarterly/',
  },
  GDPByYearUkr: {
    method: 'GET',
    url: 'Ukraine/macro/gdp_yearly/',
  },
  IndustrialByQuarterUkr: {
    method: 'GET',
    url: 'Ukraine/macro/industrial_quarterly/',
  },
  IndustrialByYearUkr: {
    method: 'GET',
    url: 'Ukraine/macro/industrial_yearly/',
  },

  GDPByQuarterUzb: {
    method: 'GET',
    url: 'Uzbekistan/macro/gdp_quarterly/',
  },
  GDPByYearUzb: {
    method: 'GET',
    url: 'Uzbekistan/macro/gdp_yearly/',
  },
  IndustrialByQuarterUzb: {
    method: 'GET',
    url: 'Uzbekistan/macro/industrial_quarterly/',
  },
  IndustrialByYearUzb: {
    method: 'GET',
    url: 'Uzbekistan/macro/industrial_yearly/',
  },

  GDPByQuarterTaj: {
    method: 'GET',
    url: 'Tajikistan/macro/gdp_quarterly/',
  },
  GDPByYearTaj: {
    method: 'GET',
    url: 'Tajikistan/macro/gdp_yearly/',
  },
  IndustrialByQuarterTaj: {
    method: 'GET',
    url: 'Tajikistan/macro/industrial_quarterly/',
  },
  IndustrialByYearTaj: {
    method: 'GET',
    url: 'Tajikistan/macro/industrial_yearly/',
  },

  GDPByQuarterGeo: {
    method: 'GET',
    url: 'Georgia/macro/gdp_quarterly/',
  },
  GDPByYearGeo: {
    method: 'GET',
    url: 'Georgia/macro/gdp_yearly/',
  },

  AgricultureQuarterlyArm: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_quarterly/',
    params: { region: 'Армения' },
  },
  AgricultureQuarterlyBel: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_quarterly/',
    params: { region: 'Беларусь' },
  },
  AgricultureQuarterlyKaz: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_quarterly/',
    params: { region: 'Казахстан' },
  },
  AgricultureQuarterlyKyr: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_quarterly/',
    params: { region: 'Кыргызстан' },
  },
  AgricultureQuarterlyRus: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_quarterly/',
    params: { region: 'Россия' },
  },
  AgricultureQuarterlyEAEU: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_quarterly/',
    params: { region: 'ЕАЭС' },
  },

  AgricultureYearlyArm: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_yearly/',
    params: { region: 'Армения' },
  },
  AgricultureYearlyBel: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_yearly/',
    params: { region: 'Беларусь' },
  },
  AgricultureYearlyKaz: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_yearly/',
    params: { region: 'Казахстан' },
  },
  AgricultureYearlyKyr: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_yearly/',
    params: { region: 'Кыргызстан' },
  },
  AgricultureYearlyRus: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_yearly/',
    params: { region: 'Россия' },
  },
  AgricultureYearlyEAEU: {
    method: 'GET',
    url: 'EAEU/macro/agriculture_yearly/',
    params: { region: 'ЕАЭС' },
  },

  YearlyAgricultureAbh: {
    method: 'GET',
    url: 'Abkhaziya/yearly_agriculture/',
  },
  YearlyGdpAbh: {
    method: 'GET',
    url: 'Abkhaziya/yearly_gdp/',
  },
  YearlyIndustrialAbh: {
    method: 'GET',
    url: 'Abkhaziya/yearly_industrial/',
  },

  YearlyAgricultureSO: {
    method: 'GET',
    url: 'hardcode/South_Ossetia/yearly_agriculture/',
  },
  YearlyGdpSO: {
    method: 'GET',
    url: 'hardcode/South_Ossetia/yearly_gdp/',
  },
  YearlyIndustrialSO: {
    method: 'GET',
    url: 'hardcode/South_Ossetia/yearly_industrial/ ',
  },
};

export default GDP;
