const tradeInGoods = {
  shareEAEUTrade: {
    method: 'GET',
    url: 'commodities_trade/share/export_within_EAEU_by_month_last_years/',
  },
  tradeTurnoverLastYearArm: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'turnover', region: 'Армения' },
  },
  tradeExportLastYearArm: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'export', region: 'Армения' },
  },
  tradeImportLastYearArm: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'import', region: 'Армения' },
  },
  tradeTurnoverLastYearBel: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'turnover', region: 'Беларусь' },
  },
  tradeExportLastYearBel: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'export', region: 'Беларусь' },
  },
  tradeImportLastYearBel: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'import', region: 'Беларусь' },
  },
  tradeTurnoverLastYearKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'turnover', region: 'Казахстан' },
  },
  tradeExportLastYearKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'export', region: 'Казахстан' },
  },
  tradeImportLastYearKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'import', region: 'Казахстан' },
  },
  tradeTurnoverLastYearKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'turnover', region: 'Кыргызстан' },
  },
  tradeExportLastYearKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'export', region: 'Кыргызстан' },
  },
  tradeImportLastYearKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'import', region: 'Кыргызстан' },
  },
  tradeTurnoverLastYearRus: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'turnover', region: 'Россия' },
  },
  tradeExportLastYearRus: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'export', region: 'Россия' },
  },
  tradeImportLastYearRus: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'import', region: 'Россия' },
  },
  tradeExportLastYearEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'export', region: 'ЕАЭС' },
  },
  tradeImportLastYearEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'import', region: 'ЕАЭС' },
  },
  tradeTurnoverLastYearEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_country_last_years/',
    params: { variable: 'turnover', region: 'ЕАЭС' },
  },
  tradeTurnoverPrevArm: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Армения' },
  },
  tradeExportPrevArm: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Армения' },
  },
  tradeImportPrevArm: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Армения' },
  },
  tradeTurnoverPrevBel: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Беларусь' },
  },
  tradeExportPrevBel: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Беларусь' },
  },
  tradeImportPrevBel: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Беларусь' },
  },
  tradeTurnoverPrevKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Казахстан' },
  },
  tradeExportPrevKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Казахстан' },
  },
  tradeImportPrevKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Казахстан' },
  },
  tradeTurnoverPrevKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Кыргызстан' },
  },
  tradeExportPrevKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Кыргызстан' },
  },
  tradeImportPrevKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Кыргызстан' },
  },
  tradeTurnoverPrevRus: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Россия' },
  },
  tradeExportPrevRus: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Россия' },
  },
  tradeImportPrevRus: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Россия' },
  },
  tradeExportPrevEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'ЕАЭС' },
  },
  tradeImportPrevEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'ЕАЭС' },
  },
  tradeTurnoverPrevEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/geo_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'ЕАЭС' },
  },
  productGroupsTradeVolumeEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_previous_years/',
    params: { region: 'ЕАЭС' },
  },
  productGroupsTradeVolumeRus: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_previous_years/',
    params: { region: 'Россия' },
  },
  productGroupsTradeVolumeBel: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_previous_years/',
    params: { region: 'Беларусь' },
  },
  productGroupsTradeVolumeArm: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_previous_years/',
    params: { region: 'Армения' },
  },
  productGroupsTradeVolumeKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_previous_years/',
    params: { region: 'Кыргызстан' },
  },
  productGroupsTradeVolumeKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_previous_years/',
    params: { region: 'Казахстан' },
  },
  productGroupsExportVolumeEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'ЕАЭС' },
  },
  productGroupsExportVolumeRus: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Россия' },
  },
  productGroupsExportVolumeBel: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Беларусь' },
  },
  productGroupsExportVolumeArm: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Армения' },
  },
  productGroupsExportVolumeKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Кыргызстан' },
  },
  productGroupsExportVolumeKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Казахстан' },
  },
  productGroupsImportVolumeEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'ЕАЭС' },
  },
  productGroupsImportVolumeRus: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Россия' },
  },
  productGroupsImportVolumeBel: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Беларусь' },
  },
  productGroupsImportVolumeArm: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Армения' },
  },
  productGroupsImportVolumeKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Кыргызстан' },
  },
  productGroupsImportVolumeKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Казахстан' },
  },
  volumeGroupsCommodityLastYearArm: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_group_last_year/',
    params: { region: 'Армения' },
  },
  volumeGroupsCommodityLastYearBel: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_group_last_year/',
    params: { region: 'Беларусь' },
  },
  volumeGroupsCommodityLastYearKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_group_last_year/',
    params: { region: 'Казахстан' },
  },
  volumeGroupsCommodityLastYearKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_group_last_year/',
    params: { region: 'Кыргызстан' },
  },
  volumeGroupsCommodityLastYearRus: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_group_last_year/',
    params: { region: 'Россия' },
  },
  volumeGroupsCommodityLastYearEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/product_structure/within_EAEU_by_group_last_year/',
    params: { region: 'ЕАЭС' },
  },

  volumeDynamicsEAEUTradeLastEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_month_last_years/',
    params: { region: 'ЕАЭС' },
  },
  volumeDynamicsEAEUTradeLastRus: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_month_last_years/',
    params: { region: 'Россия' },
  },
  volumeDynamicsEAEUTradeLastBel: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_month_last_years/',
    params: { region: 'Беларусь' },
  },
  volumeDynamicsEAEUTradeLastArm: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_month_last_years/',
    params: { region: 'Армения' },
  },
  volumeDynamicsEAEUTradeLastKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_month_last_years/',
    params: { region: 'Кыргызстан' },
  },
  volumeDynamicsEAEUTradeLastKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_month_last_years/',
    params: { region: 'Казахстан' },
  },

  tradeGoodsSummaryImportPrevEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'ЕАЭС' },
  },
  tradeGoodsSummaryExportPrevEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'ЕАЭС' },
  },
  tradeGoodsSummaryTurnoverPrevEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'ЕАЭС' },
  },
  extraTurnoverOverallTradeArm: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Армения' },
  },
  extraExportOverallTradeArm: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Армения' },
  },
  extraImportOverallTradeArm: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Армения' },
  },
  extraTurnoverOverallTradeBel: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Беларусь' },
  },
  extraExportOverallTradeBel: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Беларусь' },
  },
  extraImportOverallTradeBel: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Беларусь' },
  },
  extraTurnoverOverallTradeKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Казахстан' },
  },
  extraExportOverallTradeKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Казахстан' },
  },
  extraImportOverallTradeKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Казахстан' },
  },
  extraTurnoverOverallTradeKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Кыргызстан' },
  },
  extraExportOverallTradeKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Кыргызстан' },
  },
  extraImportOverallTradeKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Кыргызстан' },
  },
  extraTurnoverOverallTradeRus: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'turnover', region: 'Россия' },
  },
  extraExportOverallTradeRus: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Россия' },
  },
  extraImportOverallTradeRus: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Россия' },
  },
  tradeGoodsSummaryImportLastEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'import', region: 'ЕАЭС' },
  },
  tradeGoodsSummaryExportLastEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'export', region: 'ЕАЭС' },
  },
  tradeGoodsSummaryTurnoverLastEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'turnover', region: 'ЕАЭС' },
  },
  tradeGoodsSummaryImportLastRus: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'import', region: 'Россия' },
  },
  tradeGoodsSummaryExportLastRus: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'export', region: 'Россия' },
  },
  tradeGoodsSummaryTurnoverLastRus: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'turnover', region: 'Россия' },
  },
  tradeGoodsSummaryImportLastBel: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'import', region: 'Беларусь' },
  },
  tradeGoodsSummaryExportLastBel: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'export', region: 'Беларусь' },
  },
  tradeGoodsSummaryTurnoverLastBel: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'turnover', region: 'Беларусь' },
  },
  tradeGoodsSummaryImportLastArm: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'import', region: 'Армения' },
  },
  tradeGoodsSummaryExportLastArm: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'export', region: 'Армения' },
  },
  tradeGoodsSummaryTurnoverLastArm: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'turnover', region: 'Армения' },
  },
  tradeGoodsSummaryImportLastKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'import', region: 'Кыргызстан' },
  },
  tradeGoodsSummaryExportLastKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'export', region: 'Кыргызстан' },
  },
  tradeGoodsSummaryTurnoverLastKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'turnover', region: 'Кыргызстан' },
  },
  tradeGoodsSummaryImportLastKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'import', region: 'Казахстан' },
  },
  tradeGoodsSummaryExportLastKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'export', region: 'Казахстан' },
  },
  tradeGoodsSummaryTurnoverLastKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/outside_EAEU_by_month_last_years/',
    params: { variable: 'turnover', region: 'Казахстан' },
  },

  volumeDynamicsEAEUTradePrevEAEU: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_previous_years/',
    params: { variable: 'int', region: 'ЕАЭС' },
  },
  volumeDynamicsEAEUTradePrevRus: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_previous_years/',
    params: { variable: 'int', region: 'Россия' },
  },
  volumeDynamicsEAEUTradePrevBel: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_previous_years/',
    params: { variable: 'int', region: 'Беларусь' },
  },
  volumeDynamicsEAEUTradePrevArm: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_previous_years/',
    params: { variable: 'int', region: 'Армения' },
  },
  volumeDynamicsEAEUTradePrevKyr: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_previous_years/',
    params: { variable: 'int', region: 'Кыргызстан' },
  },
  volumeDynamicsEAEUTradePrevKaz: {
    method: 'GET',
    url: 'commodities_trade/volume/within_EAEU_by_previous_years/',
    params: { variable: 'int', region: 'Казахстан' },
  },
  chartShare: {
    method: 'GET',
    url: 'commodities_trade/share/export_within_EAEU_by_previous_years/',
  },

  productGroupsExportShareEAEU: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'ЕАЭС' },
  },
  productGroupsExportShareRus: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Россия' },
  },
  productGroupsExportShareBel: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Беларусь' },
  },
  productGroupsExportShareArm: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Армения' },
  },
  productGroupsExportShareKyr: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Кыргызстан' },
  },
  productGroupsExportShareKaz: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'export', region: 'Казахстан' },
  },
  productGroupsImportShareEAEU: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'ЕАЭС' },
  },
  productGroupsImportShareRus: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Россия' },
  },
  productGroupsImportShareBel: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Беларусь' },
  },
  productGroupsImportShareArm: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Армения' },
  },
  productGroupsImportShareKyr: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Кыргызстан' },
  },
  productGroupsImportShareKaz: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/outside_EAEU_by_previous_years/',
    params: { variable: 'import', region: 'Казахстан' },
  },
  productGroupsTradeShareEAEU: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/within_EAEU_by_previous_years/',
    params: { variable: 'trade', region: 'ЕАЭС' },
  },
  productGroupsTradeShareRus: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/within_EAEU_by_previous_years/',
    params: { variable: 'trade', region: 'Россия' },
  },
  productGroupsTradeShareBel: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/within_EAEU_by_previous_years/',
    params: { variable: 'trade', region: 'Беларусь' },
  },
  productGroupsTradeShareArm: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/within_EAEU_by_previous_years/',
    params: { variable: 'trade', region: 'Армения' },
  },
  productGroupsTradeShareKyr: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/within_EAEU_by_previous_years/',
    params: { variable: 'trade', region: 'Кыргызстан' },
  },
  productGroupsTradeShareKaz: {
    method: 'GET',
    url: 'commodities_trade/share/product_structure/within_EAEU_by_previous_years/',
    params: { variable: 'trade', region: 'Казахстан' },
  },
  productTradeDynamicExportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/extra_trade/',
    params: { type: 'export' },
  },
  productTradeDynamicImportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/extra_trade/',
    params: { type: 'import' },
  },
  productTradeDynamicTurnoverAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/extra_trade/',
    params: { type: 'turnover' },
  },
  productTradeDynamicQuarterlyExportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/quarterly/',
    params: { type: 'export' },
  },
  productTradeDynamicQuarterlyImportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/quarterly/',
    params: { type: 'import' },
  },
  productTradeDynamicQuarterlyTurnoverAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/quarterly/',
    params: { type: 'turnover' },
  },
  productGroupsExportShareAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productGroupsImportShareAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productGroupsExportVolumeAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productGroupsImportVolumeAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },
  commoditiesTradeDynamicExportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicImportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicTurnoverAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/dynamics/',
    params: { type: 'turnover' },
  },
  tradeExportMonthlTtop10Aze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/monthly_top10/',
    params: { type: 'export' },
  },
  tradeImportMonthlTtop10Aze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/monthly_top10/',
    params: { type: 'import' },
  },
  tradeMonthlTtop10Aze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/monthly_top10/',
    params: { type: 'turnover' },
  },

  tradeExportMonthlTtop10Ukr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/monthly_top10/',
    params: { variable: 'export' },
  },
  tradeImportMonthlTtop10Ukr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/monthly_top10/',
    params: { variable: 'import' },
  },
  tradeMonthlTtop10Ukr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/monthly_top10/',
    params: { variable: 'turnover' },
  },

  tradeExportPrevAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/yearly_top10/',
    params: { type: 'export' },
  },
  tradeImportPrevAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/yearly_top10/',
    params: { type: 'import' },
  },
  tradeTurnoverPrevAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/yearly_top10/',
    params: { type: 'turnover' },
  },
  commoditiesTradeWithRusExportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/with_russia/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusImportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/with_russia/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusTurnoverAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/with_russia/',
    params: { type: 'turnover' },
  },
  commoditiesTradeWithRusExportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/monthly_with_ru/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusImportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/monthly_with_ru/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusTurnoverMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/monthly_with_ru/',
    params: { type: 'turnover' },
  },
  commoditiesTradeWithRusExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/monthly_with_ru/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/monthly_with_ru/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusTurnoverUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/monthly_with_ru/',
    params: { type: 'turnover' },
  },
  commoditiesTradeWithRusExportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/monthly_with_ru/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusImportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/monthly_with_ru/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusTurnoverTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/monthly_with_ru/',
    params: { type: 'turnover' },
  },
  commoditiesTradeDynamicMonthlyExportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/monthly_with_eaeu/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicMonthlyImportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/monthly_with_eaeu/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicMonthlyTurnoverMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/monthly_with_eaeu/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicMonthlyExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/monthly_with_eaeu/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicMonthlyImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/monthly_with_eaeu/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicMonthlyTurnoverUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/monthly_with_eaeu/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyEAEUExportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_EAEU/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyEAEUImportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_EAEU/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyEAEUTurnoverGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_EAEU/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicMonthlyEAEUExportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_EAEU/monthly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicMonthlyEAEUImportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_EAEU/monthly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicMonthlyEAEUTurnoverGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_EAEU/monthly_dynamics/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyEAEUExportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/yearly_with_eaeu/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyEAEUImportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/yearly_with_eaeu/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyEAEUTurnoverMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/yearly_with_eaeu/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyExportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyImportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyTurnoverGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyExportUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyImportUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyTurnoverUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusExportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/monthly_with_ru/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusImportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/monthly_with_ru/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusTurnoverTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/monthly_with_ru/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusMonthlyExportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/monthly_with_ru/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusMonthlyImportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/monthly_with_ru/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusMonthlyTurnoverAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/monthly_with_ru/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusMonthlyExportUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/monthly_with_ru/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusMonthlyImportUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/monthly_with_ru/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusMonthlyTurnoverUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/monthly_with_ru/',
    params: { type: 'turnover' },
  },

  commoditiesTradeYearlyExtraExportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'export' },
  },
  commoditiesTradeYearlyExtraImportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'import' },
  },
  commoditiesTradeYearlyExtraTurnoverTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'turnover' },
  },

  commoditiesTradeYearlyExtraExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'export' },
  },
  commoditiesTradeYearlyExtraImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'import' },
  },
  commoditiesTradeYearlyExtraTurnoverUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'turnover' },
  },

  commoditiesTradeYearlyExtraExportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'export' },
  },
  commoditiesTradeYearlyExtraImportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'import' },
  },
  commoditiesTradeYearlyExtraTurnoverTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/yearly_extra_trade/',
    params: { type: 'turnover' },
  },

  tradeExportPrevTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/yearly_top10/',
    params: { type: 'export' },
  },
  tradeImportPrevTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/yearly_top10/',
    params: { type: 'import' },
  },
  tradeTurnoverPrevTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/yearly_top10/',
    params: { type: 'turnover' },
  },

  tradeExportPrevMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/yearly_top10/',
    params: { type: 'export' },
  },
  tradeImportPrevMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/yearly_top10/',
    params: { type: 'import' },
  },
  tradeTurnoverPrevMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/yearly_top10/',
    params: { type: 'turnover' },
  },

  tradeExportPrevUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/yearly_top10/',
    params: { type: 'export' },
  },
  tradeImportPrevUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/yearly_top10/',
    params: { type: 'import' },
  },
  tradeTurnoverPrevUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/yearly_top10/',
    params: { type: 'turnover' },
  },
  tradeExportPrevTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/yearly_top10/',
    params: { type: 'export' },
  },
  tradeImportPrevTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/yearly_top10/',
    params: { type: 'import' },
  },
  tradeTurnoverPrevTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/yearly_top10/',
    params: { type: 'turnover' },
  },

  tradeExportYearlyGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_top10/',
    params: { type: 'export' },
  },
  tradeImportYearlyGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_top10/',
    params: { type: 'import' },
  },
  tradeTurnoverYearlyGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_top10/',
    params: { type: 'turnover' },
  },

  tradeExportYearlyUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_top10/',
    params: { type: 'export' },
  },
  tradeImportYearlyUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_top10/',
    params: { type: 'import' },
  },
  tradeTurnoverYearlyUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_top10/',
    params: { type: 'turnover' },
  },

  tradeExportQuarterlyWithWorldUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/quarterly_with_world/',
    params: { type: 'export' },
  },
  tradeImportQuarterlyWithWorldUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/quarterly_with_world/',
    params: { type: 'import' },
  },
  tradeTurnoverQuarterlyWithWorldUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/quarterly_with_world/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicExportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicImportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicTurnoverTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/dynamics/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicExportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicImportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicTurnoverMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/dynamics/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicTurnoverUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/dynamics/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicExportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicImportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicTurnoverTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/dynamics/',
    params: { type: 'turnover' },
  },

  productGroupsExportShareTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productGroupsImportShareTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productGroupsExportVolumeTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productGroupsImportVolumeTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },

  productGroupsExportShareUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productGroupsImportShareUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productGroupsExportVolumeUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productGroupsImportVolumeUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },

  productGroupsExportShareMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productGroupsImportShareMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productGroupsExportVolumeMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productGroupsImportVolumeMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },

  productGroupsExportShareTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productGroupsImportShareTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productGroupsExportVolumeTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productGroupsImportVolumeTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },

  tradeGroupsWithRuLastImportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'import' },
  },

  tradeGroupsWithRuLastExportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'export' },
  },

  tradeGroupsWithRuLastImportUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/monthly_groups_with_ru/',
    params: { type: 'import' },
  },

  tradeGroupsWithRuLastExportUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/monthly_groups_with_ru/',
    params: { type: 'export' },
  },

  tradeGroupsWithRuLastImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'import' },
  },

  tradeGroupsWithRuLastExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'export' },
  },

  tradeGroupsWithRuLastImportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'import' },
  },

  tradeGroupsWithRuLastExportAze: {
    method: 'GET',
    url: 'Azerbaijan/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'export' },
  },

  tradeGroupsWithRuLastImportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'import' },
  },

  tradeGroupsWithRuLastExportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'export' },
  },

  tradeGroupsWithRuLastImportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'import' },
  },

  tradeGroupsWithRuLastExportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/trade_groups_with_ru_by_last_available_period/',
    params: { type: 'export' },
  },

  tradeGroupsWithRuLastImportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_Russia/monthly_trade_groups/',
    params: { type: 'import' },
  },

  tradeGroupsWithRuLastExportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_Russia/monthly_trade_groups/',
    params: { type: 'export' },
  },

  tradeGroupsYearlyImportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_trade_groups/',
    params: { type: 'import' },
  },

  productGroupsExportShareGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productGroupsImportShareGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productGroupsExportVolumeGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productGroupsImportVolumeGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },

  productGroupsExportShareUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productGroupsImportShareUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productGroupsExportVolumeUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productGroupsImportVolumeUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },

  commoditiesTradeWithRusYearlyExportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/with_russia/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusYearlyImportTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/with_russia/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusYearlyTurnoverTaj: {
    method: 'GET',
    url: 'Tajikistan/commodities_trade/with_russia/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusYearlyExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/with_russia/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusYearlyImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/with_russia/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusYearlyTurnoverUzb: {
    method: 'GET',
    url: 'Uzbekistan/commodities_trade/with_russia/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusYearlyExportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/with_russia/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusYearlyImportMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/with_russia/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusYearlyTurnoverMol: {
    method: 'GET',
    url: 'Moldova/commodities_trade/with_russia/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusYearlyExportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/with_russia/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusYearlyImportTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/with_russia/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusYearlyTurnoverTur: {
    method: 'GET',
    url: 'Turkmenistan/commodities_trade/with_russia/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusYearlyExportUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_with_ru/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusYearlyImportUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_with_ru/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusYearlyTurnoverUkr: {
    method: 'GET',
    url: 'Ukraine/commodities_trade/yearly_with_ru/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusYearlyExportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_Russia/yearly/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusYearlyImportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_Russia/yearly/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusYearlyTurnoverGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_Russia/yearly/',
    params: { type: 'turnover' },
  },

  commoditiesTradeWithRusMonthlyExportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_Russia/monthly/',
    params: { type: 'export' },
  },
  commoditiesTradeWithRusMonthlyImportGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_Russia/monthly/',
    params: { type: 'import' },
  },
  commoditiesTradeWithRusMonthlyTurnoverGeo: {
    method: 'GET',
    url: 'Georgia/commodities_trade/with_Russia/monthly/',
    params: { type: 'turnover' },
  },

  CommoditiesTradeYearlyImportAze: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Азербайджан' },
  },
  CommoditiesTradeYearlyExportAze: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Азербайджан' },
  },
  CommoditiesTradeYearlyTurnoverAze: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Азербайджан' },
  },

  CommoditiesTradeYearlyImportArm: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Армения' },
  },
  CommoditiesTradeYearlyExportArm: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Армения' },
  },
  CommoditiesTradeYearlyTurnoverArm: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Армения' },
  },

  CommoditiesTradeYearlyImportBel: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Беларусь' },
  },
  CommoditiesTradeYearlyExportBel: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Беларусь' },
  },
  CommoditiesTradeYearlyTurnoverBel: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Беларусь' },
  },

  CommoditiesTradeYearlyImportKaz: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Казахстан' },
  },
  CommoditiesTradeYearlyExportKaz: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Казахстан' },
  },
  CommoditiesTradeYearlyTurnoverKaz: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Казахстан' },
  },

  CommoditiesTradeYearlyImportKyr: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Кыргызстан' },
  },
  CommoditiesTradeYearlyExportKyr: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Кыргызстан' },
  },
  CommoditiesTradeYearlyTurnoverKyr: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Кыргызстан' },
  },

  CommoditiesTradeYearlyImportMol: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Молдова' },
  },
  CommoditiesTradeYearlyExportMol: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Молдова' },
  },
  CommoditiesTradeYearlyTurnoverMol: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Молдова' },
  },

  CommoditiesTradeYearlyImportRus: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Россия' },
  },
  CommoditiesTradeYearlyExportRus: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Россия' },
  },
  CommoditiesTradeYearlyTurnoverRus: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Россия' },
  },

  CommoditiesTradeYearlyImportTaj: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Таджикистан' },
  },
  CommoditiesTradeYearlyExportTaj: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Таджикистан' },
  },
  CommoditiesTradeYearlyTurnoverTaj: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Таджикистан' },
  },

  CommoditiesTradeYearlyImportTur: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Туркмения' },
  },
  CommoditiesTradeYearlyExportTur: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Туркмения' },
  },
  CommoditiesTradeYearlyTurnoverTur: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Туркмения' },
  },

  CommoditiesTradeYearlyImportUzb: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Узбекистан' },
  },
  CommoditiesTradeYearlyExportUzb: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Узбекистан' },
  },
  CommoditiesTradeYearlyTurnoverUzb: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Узбекистан' },
  },

  CommoditiesTradeYearlyImportUkr: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'import', region: 'Украина' },
  },
  CommoditiesTradeYearlyExportUkr: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'export', region: 'Украина' },
  },
  CommoditiesTradeYearlyTurnoverUkr: {
    method: 'GET',
    url: 'CIS/commodities_trade/yearly_cis_index/',
    params: { variable: 'turnover', region: 'Украина' },
  },

  YearlyCommoditiesStructWithRuExportAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_struct_with_ru/',
    params: { type: 'export' },
  },
  YearlyCommoditiesStructWithRuImportAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_struct_with_ru/',
    params: { type: 'import' },
  },

  YearlyCommoditiesStructWithTrExportAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_struct_with_tr/',
    params: { type: 'export' },
  },
  YearlyCommoditiesStructWithTrImportAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_struct_with_tr/',
    params: { type: 'import' },
  },

  YearlyCommoditiesTradeWithRuExportAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_trade_with_ru/',
    params: { type: 'export' },
  },
  YearlyCommoditiesTradeWithRuImportAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_trade_with_ru/',
    params: { type: 'import' },
  },
  YearlyCommoditiesTradeWithRuTurnoverAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_trade_with_ru/',
    params: { type: 'turnover' },
  },

  YearlyCommoditiesTradeExportAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_trade/',
    params: { type: 'export' },
  },
  YearlyCommoditiesTradeImportAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_trade/',
    params: { type: 'import' },
  },
  YearlyCommoditiesTradeTurnoverAbh: {
    method: 'GET',
    url: 'hardcode/Abkhaziya/yearly_commodities_trade/',
    params: { type: 'turnover' },
  },

  YearlyRuCommoditiesTradeSO: {
    method: 'GET',
    url: 'hardcode/South_Ossetia/yearly_ru_commodities_trade/',
  },

  tradeExportLastKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/top10_last_year/',
    params: { variable: 'export' },
  },
  tradeImportLastKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/top10_last_year/',
    params: { variable: 'import' },
  },
  tradeTurnoverLastKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/top10_last_year/',
    params: { variable: 'turnover' },
  },

  tradeExportYearlyKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/top10_yearly/',
    params: { variable: 'export' },
  },
  tradeImportYearlyKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/top10_yearly/',
    params: { variable: 'import' },
  },
  tradeTurnoverYearlyKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/top10_yearly/',
    params: { variable: 'turnover' },
  },

  WithEAEUStructLastYearKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/with_EAEU_struct_last_year/',
  },

  WithEAEUStructYearShareImportKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'share' },
  },
  WithEAEUStructYearVolumeImportKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'volume' },
  },
  WithEAEUStructYearShareExportKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'share' },
  },
  WithEAEUStructYearVolumeExportKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'volume' },
  },
  WithEAEUStructYearShareTurnoverKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'share' },
  },
  WithEAEUStructYearVolumeTurnoverKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'volume' },
  },

  commoditiesTradeDynamicYearlyExportKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyImportKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyTurnoverKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  productTradeGroupsExportShareKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productTradeGroupsImportShareKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productTradeGroupsExportVolumeKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productTradeGroupsImportVolumeKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },
  productTradeGroupsTurnoverVolumeKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'volume' },
  },
  productTradeGroupsTurnoverShareKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'share' },
  },

  commoditiesTradeYearlyWithEAEUImportKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_with_EAEU/',
    params: { type: 'import' },
  },
  commoditiesTradeYearlyWithEAEUExportKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_with_EAEU/',
    params: { type: 'export' },
  },
  commoditiesTradeYearlyWithEAEUTurnoverKyr: {
    method: 'GET',
    url: 'Kyrgyzstan/commodities_trade/yearly_with_EAEU/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyExportArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyImportArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyTurnoverArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  tradeExportLastArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/top10_last_year/',
    params: { variable: 'export' },
  },
  tradeImportLastArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/top10_last_year/',
    params: { variable: 'import' },
  },
  tradeTurnoverLastArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/top10_last_year/',
    params: { variable: 'turnover' },
  },

  tradeExportYearlyArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/top10_yearly/',
    params: { variable: 'export' },
  },
  tradeImportYearlyArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/top10_yearly/',
    params: { variable: 'import' },
  },
  tradeTurnoverYearlyArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/top10_yearly/',
    params: { variable: 'turnover' },
  },

  WithEAEUStructYearShareImportArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'share' },
  },
  WithEAEUStructYearVolumeImportArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'volume' },
  },
  WithEAEUStructYearShareExportArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'share' },
  },
  WithEAEUStructYearVolumeExportArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'volume' },
  },
  WithEAEUStructYearShareTurnoverArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'share' },
  },
  WithEAEUStructYearVolumeTurnoverArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'volume' },
  },

  WithEAEUStructLastYearArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/with_EAEU_struct_last_year/',
  },

  productTradeGroupsExportShareArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productTradeGroupsImportShareArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productTradeGroupsExportVolumeArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productTradeGroupsImportVolumeArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },
  productTradeGroupsTurnoverVolumeArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'volume' },
  },
  productTradeGroupsTurnoverShareArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'share' },
  },

  commoditiesTradeYearlyWithEAEUImportArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_with_EAEU/',
    params: { type: 'import' },
  },
  commoditiesTradeYearlyWithEAEUExportArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_with_EAEU/',
    params: { type: 'export' },
  },
  commoditiesTradeYearlyWithEAEUTurnoverArm: {
    method: 'GET',
    url: 'Armenia/commodities_trade/yearly_with_EAEU/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyExportRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyImportRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyTurnoverRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  tradeExportLastRus: {
    method: 'GET',
    url: 'hardcode/Russia/top10_last_year/',
    params: { variable: 'export' },
  },
  tradeImportLastRus: {
    method: 'GET',
    url: 'hardcode/Russia/top10_last_year/',
    params: { variable: 'import' },
  },
  tradeTurnoverLastRus: {
    method: 'GET',
    url: 'hardcode/Russia/top10_last_year/',
    params: { variable: 'turnover' },
  },

  tradeExportYearlyRus: {
    method: 'GET',
    url: 'hardcode/Russia/top10_yearly/',
    params: { variable: 'export' },
  },
  tradeImportYearlyRus: {
    method: 'GET',
    url: 'hardcode/Russia/top10_yearly/',
    params: { variable: 'import' },
  },
  tradeTurnoverYearlyRus: {
    method: 'GET',
    url: 'hardcode/Russia/top10_yearly/',
    params: { variable: 'turnover' },
  },

  WithEAEUStructYearShareImportRus: {
    method: 'GET',
    url: 'hardcode/Russia/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'share' },
  },
  WithEAEUStructYearVolumeImportRus: {
    method: 'GET',
    url: 'hardcode/Russia/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'volume' },
  },
  WithEAEUStructYearShareExportRus: {
    method: 'GET',
    url: 'hardcode/Russia/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'share' },
  },
  WithEAEUStructYearVolumeExportRus: {
    method: 'GET',
    url: 'hardcode/Russia/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'volume' },
  },
  WithEAEUStructYearShareTurnoverRus: {
    method: 'GET',
    url: 'hardcode/Russia/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'share' },
  },
  WithEAEUStructYearVolumeTurnoverRus: {
    method: 'GET',
    url: 'hardcode/Russia/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'volume' },
  },

  WithEAEUStructLastYearRus: {
    method: 'GET',
    url: 'hardcode/Russia/with_EAEU_struct_last_year/',
  },

  productTradeGroupsExportShareRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productTradeGroupsImportShareRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productTradeGroupsExportVolumeRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productTradeGroupsImportVolumeRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },
  productTradeGroupsTurnoverVolumeRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'volume' },
  },
  productTradeGroupsTurnoverShareRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'share' },
  },

  commoditiesTradeYearlyWithEAEUImportRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_with_EAEU/',
    params: { type: 'import' },
  },
  commoditiesTradeYearlyWithEAEUExportRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_with_EAEU/',
    params: { type: 'export' },
  },
  commoditiesTradeYearlyWithEAEUTurnoverRus: {
    method: 'GET',
    url: 'hardcode/Russia/yearly_with_EAEU/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyExportKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyImportKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyTurnoverKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  tradeExportLastKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/top10_last_year/',
    params: { variable: 'export' },
  },
  tradeImportLastKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/top10_last_year/',
    params: { variable: 'import' },
  },
  tradeTurnoverLastKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/top10_last_year/',
    params: { variable: 'turnover' },
  },

  tradeExportYearlyKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/top10_yearly/',
    params: { variable: 'export' },
  },
  tradeImportYearlyKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/top10_yearly/',
    params: { variable: 'import' },
  },
  tradeTurnoverYearlyKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/top10_yearly/',
    params: { variable: 'turnover' },
  },

  WithEAEUStructYearShareImportKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'share' },
  },
  WithEAEUStructYearVolumeImportKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'volume' },
  },
  WithEAEUStructYearShareExportKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'share' },
  },
  WithEAEUStructYearVolumeExportKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'volume' },
  },
  WithEAEUStructYearShareTurnoverKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'share' },
  },
  WithEAEUStructYearVolumeTurnoverKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'volume' },
  },

  WithEAEUStructLastYearKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/with_EAEU_struct_last_year/',
  },

  productTradeGroupsExportShareKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productTradeGroupsImportShareKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productTradeGroupsExportVolumeKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productTradeGroupsImportVolumeKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },
  productTradeGroupsTurnoverVolumeKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'volume' },
  },
  productTradeGroupsTurnoverShareKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'share' },
  },

  commoditiesTradeYearlyWithEAEUImportKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_with_EAEU/',
    params: { type: 'import' },
  },
  commoditiesTradeYearlyWithEAEUExportKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_with_EAEU/',
    params: { type: 'export' },
  },
  commoditiesTradeYearlyWithEAEUTurnoverKaz: {
    method: 'GET',
    url: 'Kazakhstan/commodities_trade/yearly_with_EAEU/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyExportBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyImportBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyTurnoverBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  tradeExportLastBel: {
    method: 'GET',
    url: 'Belarus/top10_last_year/',
    params: { variable: 'export' },
  },
  tradeImportLastBel: {
    method: 'GET',
    url: 'Belarus/top10_last_year/',
    params: { variable: 'import' },
  },
  tradeTurnoverLastBel: {
    method: 'GET',
    url: 'Belarus/top10_last_year/',
    params: { variable: 'turnover' },
  },

  tradeExportYearlyBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/top10_yearly/',
    params: { variable: 'export' },
  },
  tradeImportYearlyBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/top10_yearly/',
    params: { variable: 'import' },
  },
  tradeTurnoverYearlyBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/top10_yearly/',
    params: { variable: 'turnover' },
  },

  WithEAEUStructYearShareImportBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'share' },
  },
  WithEAEUStructYearVolumeImportBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'import', measure: 'volume' },
  },
  WithEAEUStructYearShareExportBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'share' },
  },
  WithEAEUStructYearVolumeExportBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'export', measure: 'volume' },
  },
  WithEAEUStructYearShareTurnoverBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'share' },
  },
  WithEAEUStructYearVolumeTurnoverBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/with_EAEU_struct_yearly/',
    params: { type: 'turnover', measure: 'volume' },
  },

  WithEAEUStructLastYearBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/with_EAEU_struct_last_year/',
  },

  productTradeGroupsExportShareBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productTradeGroupsImportShareBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productTradeGroupsExportVolumeBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productTradeGroupsImportVolumeBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },
  productTradeGroupsTurnoverVolumeBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'volume' },
  },
  productTradeGroupsTurnoverShareBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'share' },
  },

  commoditiesTradeYearlyWithEAEUImportBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_with_EAEU/',
    params: { type: 'import' },
  },
  commoditiesTradeYearlyWithEAEUExportBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_with_EAEU/',
    params: { type: 'export' },
  },
  commoditiesTradeYearlyWithEAEUTurnoverBel: {
    method: 'GET',
    url: 'Belarus/commodities_trade/yearly_with_EAEU/',
    params: { type: 'turnover' },
  },

  commoditiesTradeDynamicYearlyExportEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  commoditiesTradeDynamicYearlyImportEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  commoditiesTradeDynamicYearlyTurnoverEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },

  tradeExportYearlyEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/top10_yearly/',
    params: { variable: 'export' },
  },
  tradeImportYearlyEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/top10_yearly/',
    params: { variable: 'import' },
  },
  tradeTurnoverYearlyEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/top10_yearly/',
    params: { variable: 'turnover' },
  },

  WithEAEUStructLastYearEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/with_EAEU_struct_last_year/',
  },

  productTradeGroupsExportShareEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'share' },
  },
  productTradeGroupsImportShareEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'share' },
  },
  productTradeGroupsExportVolumeEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_trade_groups/',
    params: { type: 'export', measure: 'volume' },
  },
  productTradeGroupsImportVolumeEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_trade_groups/',
    params: { type: 'import', measure: 'volume' },
  },
  productTradeGroupsTurnoverVolumeEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'volume' },
  },
  productTradeGroupsTurnoverShareEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_trade_groups/',
    params: { type: 'turnover', measure: 'share' },
  },

  commoditiesTradeYearlyWithEAEU: {
    method: 'GET',
    url: 'EAEU/commodities_trade/yearly_with_EAEU/',
  },
};

export default tradeInGoods;
