import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  DynamicsHistoricalRus: {
    Russia: [
      // {
      //   title: 'Торговля со странами ЕАЭС',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastRus },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastRus },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastRus },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastRus },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraExportOverallTradeRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraImportOverallTradeRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraTurnoverOverallTradeRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
  },

  DynamicsHistorical: {
    // Russia: [
    //   // {
    //   //   title: 'Торговля со странами ЕАЭС',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastRus },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Экспорт',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastRus },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Импорт',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastRus },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Оборот',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastRus },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Торговля со странами ЕАЭС',
    //   //   filter: 'Исторические',
    //   //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevRus },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Экспорт',
    //   //   filter: 'Исторические',
    //   //   request: { ...API.tradeInGoods.extraExportOverallTradeRus },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Импорт',
    //   //   filter: 'Исторические',
    //   //   request: { ...API.tradeInGoods.extraImportOverallTradeRus },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Оборот',
    //   //   filter: 'Исторические',
    //   //   request: { ...API.tradeInGoods.extraTurnoverOverallTradeRus },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    // ],
    Belarus: [
      // {
      //   title: 'Торговля со странами ЕАЭС',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastBel },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastBel },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastBel },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastBel },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraExportOverallTradeBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraImportOverallTradeBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraTurnoverOverallTradeBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    // Armenia: [
    //   // {
    //   //   title: 'Торговля со странами ЕАЭС',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastArm },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Экспорт',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastArm },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Импорт',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastArm },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Оборот',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastArm },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   {
    //     title: 'Торговля со странами ЕАЭС',
    //     filter: 'Исторические',
    //     request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevArm },
    //     chart: 'SplineBarChart',
    //     chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   },
    //   {
    //     title: 'Экспорт',
    //     filter: 'Исторические',
    //     request: { ...API.tradeInGoods.extraExportOverallTradeArm },
    //     chart: 'SplineBarChart',
    //     chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   },
    //   {
    //     title: 'Импорт',
    //     filter: 'Исторические',
    //     request: { ...API.tradeInGoods.extraImportOverallTradeArm },
    //     chart: 'SplineBarChart',
    //     chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   },
    //   {
    //     title: 'Оборот',
    //     filter: 'Исторические',
    //     request: { ...API.tradeInGoods.extraTurnoverOverallTradeArm },
    //     chart: 'SplineBarChart',
    //     chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   },
    // ],
    // Kyrgyzstan: [
    //   // {
    //   //   title: 'Торговля со странами ЕАЭС',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastKyr },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Экспорт',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastKyr },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Импорт',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastKyr },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Оборот',
    //   //   filter: 'Актуальные',
    //   //   request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastKyr },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Торговля со странами ЕАЭС',
    //   //   filter: 'Исторические',
    //   //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevKyr },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Экспорт',
    //   //   filter: 'Исторические',
    //   //   request: { ...API.tradeInGoods.extraExportOverallTradeKyr },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Импорт',
    //   //   filter: 'Исторические',
    //   //   request: { ...API.tradeInGoods.extraImportOverallTradeKyr },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    //   // {
    //   //   title: 'Оборот',
    //   //   filter: 'Исторические',
    //   //   request: { ...API.tradeInGoods.extraTurnoverOverallTradeKyr },
    //   //   chart: 'SplineBarChart',
    //   //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    //   // },
    // ],
    Kazakhstan: [
      // {
      //   title: 'Торговля со странами ЕАЭС',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastKaz },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastKaz },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastKaz },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastKaz },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraExportOverallTradeKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraImportOverallTradeKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraTurnoverOverallTradeKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
  },
  Dynamics: {
    EAEU: [
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.tradeGoodsSummaryExportPrevEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.tradeGoodsSummaryImportPrevEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverPrevEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Russia: [
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraExportOverallTradeRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraImportOverallTradeRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraTurnoverOverallTradeRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Belarus: [
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraExportOverallTradeBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraImportOverallTradeBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraTurnoverOverallTradeBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Armenia: [
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraExportOverallTradeArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraImportOverallTradeArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraTurnoverOverallTradeArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraExportOverallTradeKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraImportOverallTradeKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraTurnoverOverallTradeKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryExportLastKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryImportLastKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'Актуальные',
        request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.volumeDynamicsEAEUTradePrevKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Экспорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraExportOverallTradeKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraImportOverallTradeKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Оборот',
        filter: 'Исторические',
        request: { ...API.tradeInGoods.extraTurnoverOverallTradeKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
  },
  ShareMemberStatesEAEU: [
    {
      title: 'Актуальные',
      request: { ...API.tradeInGoods.shareEAEUTrade },
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset1 },
    },
    {
      title: 'Исторические',
      request: { ...API.tradeInGoods.chartShare },
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset1 },
    },
  ],
  MutualTradeGrowth: {
    EAEU: [
      {
        title: '',
        request: { ...API.tradeInGoods.volumeGroupsCommodityLastYearEAEU },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Russia: [
      {
        title: '',
        request: { ...API.tradeInGoods.volumeGroupsCommodityLastYearRus },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Belarus: [
      {
        title: '',
        request: { ...API.tradeInGoods.volumeGroupsCommodityLastYearBel },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Armenia: [
      {
        title: '',
        request: { ...API.tradeInGoods.volumeGroupsCommodityLastYearArm },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Kyrgyzstan: [
      {
        title: '',
        request: { ...API.tradeInGoods.volumeGroupsCommodityLastYearKyr },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        request: { ...API.tradeInGoods.volumeGroupsCommodityLastYearKaz },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
  },
  Top10ByValueVolume: {
    EAEU: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearEAEU },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearEAEU },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearEAEU },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevEAEU },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevEAEU },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevEAEU },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    // Russia: [
    //   {
    //     title: 'Экспорт',
    //     request: { ...API.tradeInGoods.tradeExportLastYearRus },
    //     filter: 'За 2022 год',
    //     chart: 'BarChart',
    //     chartOptions: { ...ChartOptions.BarChart.preset5 },
    //   },
    //   {
    //     title: 'Импорт',
    //     request: { ...API.tradeInGoods.tradeImportLastYearRus },
    //     filter: 'За 2022 год',
    //     chart: 'BarChart',
    //     chartOptions: { ...ChartOptions.BarChart.preset5 },
    //   },
    //   {
    //     title: 'Оборот',
    //     request: { ...API.tradeInGoods.tradeTurnoverLastYearRus },
    //     filter: 'За 2022 год',
    //     chart: 'BarChart',
    //     chartOptions: { ...ChartOptions.BarChart.preset5 },
    //   },
    //   {
    //     title: 'Экспорт',
    //     request: { ...API.tradeInGoods.tradeExportPrevRus },
    //     filter: 'По годам',
    //     chart: 'HMBarChart',
    //     chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    //   },
    //   {
    //     title: 'Импорт',
    //     request: { ...API.tradeInGoods.tradeImportPrevRus },
    //     filter: 'По годам',
    //     chart: 'HMBarChart',
    //     chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    //   },
    //   {
    //     title: 'Оборот',
    //     request: { ...API.tradeInGoods.tradeTurnoverPrevRus },
    //     filter: 'По годам',
    //     chart: 'HMBarChart',
    //     chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    //   },
    // ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearBel },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearBel },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearBel },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevBel },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevBel },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevBel },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearArm },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearArm },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearArm },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearKyr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearKyr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearKyr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearKaz },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearKaz },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearKaz },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportMonthlTtop10Aze },
        filter: 'За 2022 год',

        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportMonthlTtop10Aze },
        filter: 'За 2022 год',

        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeMonthlTtop10Aze },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevAze },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevAze },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevAze },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
  },

  // Top10ByValueVolumeUkr: [
  //   {
  //     title: 'Экспорт',
  //     request: { ...API.tradeInGoods.tradeExportMonthlTtop10Ukr },
  //     chart: 'BarChart',
  //     chartOptions: { ...ChartOptions.BarChart.preset5 },
  //   },
  //   {
  //     title: 'Импорт',
  //     request: { ...API.tradeInGoods.tradeImportMonthlTtop10Ukr },
  //     chart: 'BarChart',
  //     chartOptions: { ...ChartOptions.BarChart.preset5 },
  //   },
  //   {
  //     title: 'Оборот',
  //     request: { ...API.tradeInGoods.tradeMonthlTtop10Ukr },
  //     chart: 'BarChart',
  //     chartOptions: { ...ChartOptions.BarChart.preset5 },
  //   },
  // ],

  Top10ByValueVolumeUnionState: {
    EAEU: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearEAEU },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearEAEU },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearEAEU },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevEAEU },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevEAEU },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevEAEU },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearRus },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearRus },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearRus },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevRus },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevRus },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevRus },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearBel },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearBel },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearBel },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevBel },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevBel },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevBel },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearArm },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearArm },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearArm },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearKyr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearKyr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearKyr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastYearKaz },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastYearKaz },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastYearKaz },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportMonthlTtop10Aze },
        filter: 'За 2022 год',

        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportMonthlTtop10Aze },
        filter: 'За 2022 год',

        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeMonthlTtop10Aze },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevAze },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevAze },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevAze },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportMonthlTtop10Ukr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportMonthlTtop10Ukr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeMonthlTtop10Ukr },
        filter: 'За 2022 год',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportYearlyUkr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportYearlyUkr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverYearlyUkr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
  },

  Top10ByValueVolumeUnionStateAze: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.tradeExportMonthlTtop10Aze },
      filter: 'За 2023 год',

      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.tradeImportMonthlTtop10Aze },
      filter: 'За 2023 год',

      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.tradeMonthlTtop10Aze },
      filter: 'За 2023 год',
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.tradeExportPrevAze },
      filter: 'По годам',
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.tradeImportPrevAze },
      filter: 'По годам',
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.tradeTurnoverPrevAze },
      filter: 'По годам',
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
  ],

  tradeQuarterlyWithWorldUkr: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.tradeExportQuarterlyWithWorldUkr },

      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.tradeImportQuarterlyWithWorldUkr },

      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.tradeTurnoverQuarterlyWithWorldUkr },

      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  Top10ByValueVolumeRus: {
    Russia: [
      // {
      //   title: 'Экспорт',
      //   request: { ...API.tradeInGoods.tradeExportLastYearRus },
      //   filter: 'За 2022 год',
      //   chart: 'BarChart',
      //   chartOptions: { ...ChartOptions.BarChart.preset5 },
      // },
      // {
      //   title: 'Импорт',
      //   request: { ...API.tradeInGoods.tradeImportLastYearRus },
      //   filter: 'За 2022 год',
      //   chart: 'BarChart',
      //   chartOptions: { ...ChartOptions.BarChart.preset5 },
      // },
      // {
      //   title: 'Оборот',
      //   request: { ...API.tradeInGoods.tradeTurnoverLastYearRus },
      //   filter: 'За 2022 год',
      //   chart: 'BarChart',
      //   chartOptions: { ...ChartOptions.BarChart.preset5 },
      // },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevRus },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevRus },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevRus },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
  },

  ProductGroups: {
    EAEU: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareEAEU },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareEAEU },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля',
        request: { ...API.tradeInGoods.productGroupsTradeShareEAEU },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeEAEU },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeEAEU },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля',
        request: { ...API.tradeInGoods.productGroupsTradeVolumeEAEU },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareRus },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareRus },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        request: { ...API.tradeInGoods.productGroupsTradeShareRus },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeRus },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeRus },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        request: { ...API.tradeInGoods.productGroupsTradeVolumeRus },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareBel },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareBel },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        request: { ...API.tradeInGoods.productGroupsTradeShareBel },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeBel },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeBel },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        request: { ...API.tradeInGoods.productGroupsTradeVolumeBel },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareArm },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareArm },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        request: { ...API.tradeInGoods.productGroupsTradeShareArm },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeArm },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeArm },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        request: { ...API.tradeInGoods.productGroupsTradeVolumeArm },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    // Kyrgyzstan: [
    //   {
    //     title: 'Экспорт',
    //     request: { ...API.tradeInGoods.productGroupsExportShareKyr },
    //     filter: 'Доли',
    //     chart: 'HBarChart',
    //     chartOptions: { ...ChartOptions.HBarChart.preset8 },
    //   },
    //   {
    //     title: 'Импорт',
    //     request: { ...API.tradeInGoods.productGroupsImportShareKyr },
    //     filter: 'Доли',
    //     chart: 'HBarChart',
    //     chartOptions: { ...ChartOptions.HBarChart.preset8 },
    //   },
    //   {
    //     title: 'Торговля со странами ЕАЭС',
    //     request: { ...API.tradeInGoods.productGroupsTradeShareKyr },
    //     filter: 'Доли',
    //     chart: 'HBarChart',
    //     chartOptions: { ...ChartOptions.HBarChart.preset8 },
    //   },

    //   {
    //     title: 'Экспорт',
    //     request: { ...API.tradeInGoods.productGroupsExportVolumeKyr },
    //     filter: 'Объем',
    //     chart: 'HBarChart',
    //     chartOptions: { ...ChartOptions.HBarChart.preset8 },
    //   },
    //   {
    //     title: 'Импорт',
    //     request: { ...API.tradeInGoods.productGroupsImportVolumeKyr },
    //     filter: 'Объем',
    //     chart: 'HBarChart',
    //     chartOptions: { ...ChartOptions.HBarChart.preset8 },
    //   },
    //   {
    //     title: 'Торговля со странами ЕАЭС',
    //     request: { ...API.tradeInGoods.productGroupsTradeVolumeKyr },
    //     filter: 'Объем',
    //     chart: 'HBarChart',
    //     chartOptions: { ...ChartOptions.HBarChart.preset8 },
    //   },
    // ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareKaz },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareKaz },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        request: { ...API.tradeInGoods.productGroupsTradeShareKaz },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeKaz },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeKaz },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Торговля со странами ЕАЭС',
        request: { ...API.tradeInGoods.productGroupsTradeVolumeKaz },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareAze },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareAze },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeAze },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeAze },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareTaj },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareTaj },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeTaj },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeTaj },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareGeo },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareGeo },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeGeo },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeGeo },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareUkr },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareUkr },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeUkr },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeUkr },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareUzb },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareUzb },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeUzb },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeUzb },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareMol },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareMol },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeMol },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeMol },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Turkmenistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportShareTur },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportShareTur },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productGroupsExportVolumeTur },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productGroupsImportVolumeTur },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
  },

  ProductTradeDynamicAze: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productTradeDynamicExportAze },
      filter: 'По годам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productTradeDynamicImportAze },
      filter: 'По годам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.productTradeDynamicTurnoverAze },
      filter: 'По годам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productTradeDynamicQuarterlyExportAze },
      filter: 'По кварталам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productTradeDynamicQuarterlyImportAze },
      filter: 'По кварталам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.productTradeDynamicQuarterlyTurnoverAze },
      filter: 'По кварталам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  ProductTradeDynamicukr: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productTradeDynamicExportAze },
      filter: 'По годам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productTradeDynamicImportAze },
      filter: 'По годам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.productTradeDynamicTurnoverAze },
      filter: 'По годам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productTradeDynamicQuarterlyExportAze },
      filter: 'По кварталам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productTradeDynamicQuarterlyImportAze },
      filter: 'По кварталам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.productTradeDynamicQuarterlyTurnoverAze },
      filter: 'По кварталам',
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  CommoditiesTradeDynamicMonthly: {
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyExportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyImportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyTurnoverMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyExportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyImportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyTurnoverUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
  },

  CommoditiesTradeMonthlyExtra: {
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyEAEUExportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyEAEUImportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicMonthlyEAEUTurnoverGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyEAEUExportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyEAEUImportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyEAEUTurnoverMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
  },

  CommoditiesTradeYearlyExtra: {
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyEAEUExportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyEAEUImportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyEAEUTurnoverGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraExportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraImportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraTurnoverTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],

    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraExportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraImportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraTurnoverUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],

    Turkmenistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraExportTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraImportTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyExtraTurnoverTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
  },

  YearlyTop10: {
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevTaj },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevTaj },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevTaj },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportYearlyGeo },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportYearlyGeo },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverYearlyGeo },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevUzb },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevUzb },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevUzb },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevMol },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevMol },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevMol },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Turkmenistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportPrevTur },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportPrevTur },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverPrevTur },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportYearlyUkr },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportYearlyUkr },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverYearlyUkr },
        // filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
  },

  CommoditiesTradeWithRus: {
    Azerbaijan: [
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyExportAze },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyImportAze },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyTurnoverAze },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Экспорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusExportAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusImportAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusTurnoverAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Tajikistan: [
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusExportTaj },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusImportTaj },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusTurnoverTaj },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Экспорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyExportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyImportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyTurnoverTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Uzbekistan: [
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusExportUzb },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusImportUzb },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusTurnoverUzb },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Экспорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyExportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyImportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyTurnoverUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Georgia: [
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyExportGeo },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyImportGeo },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyTurnoverGeo },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Экспорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyExportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyImportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyTurnoverGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Moldova: [
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusExportMol },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusImportMol },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusTurnoverMol },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Экспорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyExportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyImportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyTurnoverMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Turkmenistan: [
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusExportTur },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusImportTur },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusTurnoverTur },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Экспорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyExportTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyImportTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyTurnoverTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],

    Ukraine: [
      // {
      //   title: 'Экспорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyExportUkr },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Импорт',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyImportUkr },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      // {
      //   title: 'Оборот',
      //   filter: 'Актуальные',
      //   request: { ...API.tradeInGoods.commoditiesTradeWithRusMonthlyTurnoverUkr },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Экспорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyExportUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyImportUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        // filter: 'Исторические',
        request: { ...API.tradeInGoods.commoditiesTradeWithRusYearlyTurnoverUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  CommoditiesTradeDynamic: {
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicExportAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicImportAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicTurnoverAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicExportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicImportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicTurnoverTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicExportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicImportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicTurnoverUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicExportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicImportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicTurnoverMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Turkmenistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicExportTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicImportTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicTurnoverTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  CommoditiesTradeDynamicNatSources: {
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  TradeGroupsWithRuLast: {
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastExportAze },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastImportAze },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastExportTaj },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastImportTaj },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastExportUzb },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastImportUzb },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastExportUkr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastImportUkr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastExportGeo },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastImportGeo },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastExportMol },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastImportMol },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Turkmenistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastExportTur },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeGroupsWithRuLastImportTur },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
  },

  CommoditiesTradeYearly: {
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportAze },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportAze },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverAze },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportMol },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportMol },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverMol },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportTaj },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportTaj },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverTaj },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportUzb },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportUzb },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverUzb },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportUkr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportUkr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverUkr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
    Turkmenistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyExportTur },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyImportTur },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.CommoditiesTradeYearlyTurnoverTur },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
      },
    ],
  },

  YearlyCommoditiesStructWithRuAbh: [
    {
      title: 'Экспорт',
      chart: 'HMBarChart',
      request: { ...API.tradeInGoods.YearlyCommoditiesStructWithRuExportAbh },
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
    {
      title: 'Импорт',
      chart: 'HMBarChart',
      request: { ...API.tradeInGoods.YearlyCommoditiesStructWithRuImportAbh },
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
  ],

  YearlyCommoditiesStructWithTrAbh: [
    {
      title: 'Экспорт',
      chart: 'HMBarChart',
      request: { ...API.tradeInGoods.YearlyCommoditiesStructWithTrExportAbh },
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
    {
      title: 'Импорт',
      chart: 'HMBarChart',
      request: { ...API.tradeInGoods.YearlyCommoditiesStructWithTrImportAbh },
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
  ],

  YearlyCommoditiesTradeWithRuAbh: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.YearlyCommoditiesTradeWithRuExportAbh },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.YearlyCommoditiesTradeWithRuImportAbh },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.YearlyCommoditiesTradeWithRuTurnoverAbh },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  YearlyCommoditiesTradeAbh: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.YearlyCommoditiesTradeExportAbh },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.YearlyCommoditiesTradeImportAbh },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.YearlyCommoditiesTradeTurnoverAbh },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  YearlyRuCommoditiesTradeSO: [
    {
      title: '',
      request: { ...API.tradeInGoods.YearlyRuCommoditiesTradeSO },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  Top10ByValueVolumeNatSources: {
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastKyr },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastKyr },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastKyr },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportYearlyKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportYearlyKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverYearlyKyr },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastArm },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastArm },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastArm },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportYearlyArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportYearlyArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverYearlyArm },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],

    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastRus },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastRus },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastRus },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportYearlyRus },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportYearlyRus },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverYearlyRus },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],

    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportLastKaz },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportLastKaz },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverLastKaz },
        filter: 'Актуальные',
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.tradeExportYearlyKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.tradeImportYearlyKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.tradeTurnoverYearlyKaz },
        filter: 'По годам',
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],

    Belarus: [
      {
        title: 'Экспорт',
        filter: 'По годам',
        request: { ...API.tradeInGoods.tradeExportYearlyBel },
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Импорт',
        filter: 'По годам',
        request: { ...API.tradeInGoods.tradeImportYearlyBel },
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
      {
        title: 'Оборот',
        filter: 'По годам',
        request: { ...API.tradeInGoods.tradeTurnoverYearlyBel },
        chart: 'HMBarChart',
        chartOptions: { ...ChartOptions.HMBarChart.preset2 },
      },
    ],
  },

  WithEAEUStructYearNatSources: {
    Armenia: [
      {
        title: 'Импорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareImportArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareExportArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareTurnoverArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeImportArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeExportArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeTurnoverArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Russia: [
      {
        title: 'Импорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareImportRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareExportRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareTurnoverRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeImportRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeExportRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeTurnoverRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Импорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareImportKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareExportKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareTurnoverKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeImportKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeExportKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeTurnoverKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Belarus: [
      {
        title: 'Импорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareImportBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareExportBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareTurnoverBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeImportBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeExportBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeTurnoverBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Импорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareImportKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareExportKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Доли',
        request: { ...API.tradeInGoods.WithEAEUStructYearShareTurnoverKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeImportKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeExportKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        filter: 'Объем',
        request: { ...API.tradeInGoods.WithEAEUStructYearVolumeTurnoverKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
  },

  WithEAEUStructLastYearNatSources: {
    Russia: [
      {
        title: '',
        request: { ...API.tradeInGoods.WithEAEUStructLastYearRus },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset6 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        request: { ...API.tradeInGoods.WithEAEUStructLastYearKaz },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset6 },
      },
    ],

    Belarus: [
      {
        title: '',
        request: { ...API.tradeInGoods.WithEAEUStructLastYearBel },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset6 },
      },
    ],
    Kyrgyzstan: [
      {
        title: '',
        request: { ...API.tradeInGoods.WithEAEUStructLastYearKyr },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset6 },
      },
    ],

    Armenia: [
      {
        title: '',
        request: { ...API.tradeInGoods.WithEAEUStructLastYearArm },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset6 },
      },
    ],
  },

  ProductTradeGroupsShareNatSources: {
    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportShareRus },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportShareRus },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverShareRus },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportVolumeRus },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportVolumeRus },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverVolumeRus },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportShareKyr },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportShareKyr },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverShareKyr },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportVolumeKyr },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportVolumeKyr },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverVolumeKyr },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportShareArm },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportShareArm },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverShareArm },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportVolumeArm },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportVolumeArm },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverVolumeArm },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportShareBel },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportShareBel },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverShareBel },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportVolumeBel },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportVolumeBel },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },

      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverVolumeBel },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportShareKaz },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportShareKaz },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverShareKaz },
        filter: 'Доли',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.productTradeGroupsExportVolumeKaz },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.productTradeGroupsImportVolumeKaz },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.productTradeGroupsTurnoverVolumeKaz },
        filter: 'Объем',
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset8 },
      },
    ],
  },

  commoditiesTradeYearlyWithEAEUNatSources: {
    Russia: [
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUImportRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUExportRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUTurnoverRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Armenia: [
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUImportArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUExportArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUTurnoverArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUImportKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUExportKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUTurnoverKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Belarus: [
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUImportBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUExportBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUTurnoverBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Импорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUImportKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Экспорт',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUExportKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEUTurnoverKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
  },

  commoditiesTradeYearlyWithEAEU: [
    {
      title: '',
      request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  CommoditiesTradeDynamicEAEU: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  ProductTradeGroupsShareKyr: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productTradeGroupsExportShareKyr },
      filter: 'Доли',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productTradeGroupsImportShareKyr },
      filter: 'Доли',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productTradeGroupsExportVolumeKyr },
      filter: 'Объем',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productTradeGroupsImportVolumeKyr },
      filter: 'Объем',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
  ],
};
