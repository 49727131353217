const consumerPriceIndex = {
  cpiQuarter: {
    method: 'GET',
    url: 'CIS/CPI/by_quarter_last_years/',
  },
  cpiYear: {
    method: 'GET',
    url: 'CIS/CPI/by_previous_years/',
  },
  cpiQuarterArm: {
    method: 'GET',
    url: 'CIS/CPI/by_quarter_last_years/',
    params: { region: 'Армения' },
  },
  cpiYearArm: {
    method: 'GET',
    url: 'CIS/CPI/by_previous_years/',
    params: { region: 'Армения' },
  },
  cpiQuarterBel: {
    method: 'GET',
    url: 'CIS/CPI/by_quarter_last_years/',
    params: { region: 'Беларусь' },
  },
  cpiYearBel: {
    method: 'GET',
    url: 'CIS/CPI/by_previous_years/',
    params: { region: 'Беларусь' },
  },
  cpiQuarterKaz: {
    method: 'GET',
    url: 'CIS/CPI/by_quarter_last_years/',
    params: { region: 'Казахстан' },
  },
  cpiYearKaz: {
    method: 'GET',
    url: 'CIS/CPI/by_previous_years/',
    params: { region: 'Казахстан' },
  },
  cpiQuarterKyr: {
    method: 'GET',
    url: 'CIS/CPI/by_quarter_last_years/',
    params: { region: 'Кыргызстан' },
  },
  cpiYearKyr: {
    method: 'GET',
    url: 'CIS/CPI/by_previous_years/',
    params: { region: 'Кыргызстан' },
  },
  cpiQuarterRus: {
    method: 'GET',
    url: 'CIS/CPI/by_quarter_last_years/',
    params: { region: 'Россия' },
  },
  cpiYearRus: {
    method: 'GET',
    url: 'CIS/CPI/by_previous_years/',
    params: { region: 'Россия' },
  },
  cpiQuarterMol: {
    method: 'GET',
    url: 'CIS/CPI/by_quarter_last_years/',
    params: { region: 'Молдова' },
  },
  cpiYearMol: {
    method: 'GET',
    url: 'CIS/CPI/by_previous_years/',
    params: { region: 'Молдова' },
  },
  monetaryBankRate: {
    method: 'GET',
    url: 'Belarus/macro/base_rate_by_year/',
  },
};

export default consumerPriceIndex;
