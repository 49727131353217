import React from 'react';
import Comment from 'components/Comment';
import { Tooltip } from 'frontend-react-library/lib/ui/Tooltip';

import Table from 'components/Table';
import armSmall from 'assets/img/armSmall.png';
import belSmall from 'assets/img/belSmall.png';
import kazSmall from 'assets/img/kazSmall.png';
import kyrSmall from 'assets/img/kyrSmall.png';
import rusSmall from 'assets/img/rusSmall.png';
// import uzbSmall from 'assets/img/uzbSmall.png';
// import molSmall from 'assets/img/molSmall.png';

import styles from './PublicPages.module.scss';

const PublicPage: React.FC = () => {
  return (
    <div>
      {process.env.REACT_APP_ROUTERS === 'full_routers' && (
        <>
          <h1 id="_1">Евразийская интеграция в цифрах: экономический мониторинг</h1>
          <h2 id="Макроэкономика">Макроэкономика</h2>
          <div className={styles.containerTwoBlocks}>
            <div className={styles.itemTwoBlocks}>
              <h3 id="Прирост_ВВП">
                Валовый внутренний продукт: (+3,8% за январь-декабрь 2023&nbsp;г., к аналогичному
                периоду 2022&nbsp;г.)
              </h3>

              <p style={{ marginBottom: `${20}px` }}>
                В реальном выражении прирост{' '}
                <Tooltip text="Валовый внутренний продукт">
                  <span>ВВП</span>
                </Tooltip>{' '}
                по итогу 2023 г. в Армении составил +8,7%, в Беларуси +3,9%, в Казахстане +5,1%, в
                Киргизии +6,2%, в России +3,6%. Рост совокупного{' '}
                <Tooltip text="Валовый внутренний продукт">
                  <span>ВВП</span>
                </Tooltip>{' '}
                <Tooltip text="Евразийский экономический союз">
                  <span>ЕАЭС</span>
                </Tooltip>{' '}
                за 12 месяцев 2023 г. по сравнению с аналогичным периодом предшествующего года
                составил +3,8%.
              </p>
            </div>

            <div className={styles.itemTwoBlocks}>
              <h3 id="Промышленное_производство">
                Промышленное производство: (+6,6% за январь-февраль 2024&nbsp;г., к аналогичному
                периоду 2023&nbsp;г.)
              </h3>
              <p>
                По данным{' '}
                <Tooltip text="Евразийская экономическая комиссия">
                  <span>ЕЭК</span>
                </Tooltip>{' '}
                , в январе – феврале 2024 г. по сравнению с аналогичным периодом предыдущего года
                промышленное производство увеличилось во всех странах-членах{' '}
                <Tooltip text="Евразийский экономический союз">
                  <span>ЕАЭС</span>
                </Tooltip>{' '}
                : в Армении – на 28,9%, в Беларуси – на 7,1%, в Казахстане – на 4,6%, в Кыргызстане
                – на 7,8%, в России – на 6,6%. В целом по Союзу прирост в отрасли составил 6,6%, при
                этом объемы выпуска продукции в обрабатывающем секторе возросли на 10,4%, а в
                горнодобывающей промышленности масштабы производства возросли на 1,5%.
              </p>
            </div>
          </div>
          <h2 id="Индекс_потребительских_цен">Индекс потребительских цен</h2>
          <div className={styles.itemOneBlock}>
            <h3 id="Индекс_потребительских_цен">
              Индекс потребительских цен: +2,0% (март 2024&nbsp;г. к декабрю 2023&nbsp;г.)
            </h3>
            <p>
              По итогу 2023 г. рост инфляции в{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              составил 7,5% по отношению к декабрю 2022&nbsp;г., в т.ч. средних цен на
              продовольственные и непродовольственные товары – 7,8% и 6,3%, соответственно. Цены на
              платные услуги увеличились на 8,7%.
            </p>
            <p>
              Потребительская инфляция в марте 2024&nbsp;г. по отношению к декабрю предшествующего
              года в целом по странам-членам Союза выросла на 2,0%, в том числе в Армении – на 0,2%,
              Беларуси – на 2,2%, в Казахстане – на 2,6%, в Киргизии – на 1,2%, в России – на 2,0%.{' '}
            </p>
          </div>
          <h2 id="Безработица">Безработица</h2>
          <div className={styles.itemOneBlock}>
            <h3 id="Безработица">
              Безработица: -13,0% (на конец февраля 2024&nbsp;г. в сравнении с аналогичной датой
              2023&nbsp;г.)
            </h3>
            <p>
              В конце февраля 2024 г. по сравнению с аналогичной датой 2023 г. численность
              безработных в целом по{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              уменьшилась на 13,0%, достигнув 827,5 тыс чел. В Армении сокращение составило 3,8%, в
              Беларуси – 27,1%, в Киргизии – 12,0%, в России – 26,4%. В Казахстане число безработных
              выросло на 12,6%.
            </p>
          </div>
          <h2 id="Финансовый_сектор">Финансовый сектор</h2>
          <div className={styles.containerTwoBlocks}>
            <div className={styles.itemTwoBlocks}>
              <h3 id="Дефицит_бюджета">
                Государственный бюджет: -$45,3&nbsp;млрд за январь-декабрь 2023 г. (-54,6&nbsp;млрд
                $ – значение за аналогичный период прошлого года; без учета данных по Беларуси)
              </h3>
              <p>
                В январе-декабре 2023&nbsp;г. госбюджет был исполнен с профицитом в Кыргызстане. В
                Армении дефицит бюджета увеличился на 0,1 млрд $ относительно значения за
                аналогичный период прошлого года, в Казахстане – на 1,6 млрд $, а в России
                зафиксировано сокращение бюджетного дефицита в объеме 10,6 млрд $ (данные по
                Беларуси для этого периода отсутствуют).
              </p>
            </div>
            <div className={styles.itemTwoBlocks}>
              <h3 id="Госдолг">
                Госдолг (без учета данных по Беларуси): -3,8% (-15,2&nbsp;млрд&nbsp;$) (на 1 января
                2024&nbsp;г. в сравнении с аналогичной датой прошлого года)
              </h3>
              <p>
                К началу 2024&nbsp;г. во всех странах-членах Союза, кроме России (данные по Беларуси
                за этот год отсутствуют), зафиксировано увеличение государственной задолженности,
                выраженный в иностранной валюте (долларе США). По состоянию на 1 января 2024&nbsp;г.
                относительно аналогичной даты предыдущего года госдолг в долларовом эквиваленте в
                Республике Армения вырос на 11,3%, составив 11,8 млрд $, в Республике Казахстан – на
                9,1% (до 59,9&nbsp;млрд&nbsp;$), в Кыргызской Республике – на 12,5% (до
                6,3&nbsp;млрд&nbsp;$). В Российской Федерации госдолг сократился на 6,8% (до
                302,3&nbsp;млрд&nbsp;$).
              </p>
            </div>
          </div>

          <h2 id="Взаимная_и_внешняя_торговля_товарами">Взаимная торговля</h2>
          <div className={styles.itemOneBlock}>
            <h3 id="Взаимная_и_внешняя_торговля_товарами">
              Взаимная торговля: +5,1% (+4,2&nbsp;млрд&nbsp;$) (за январь-декабрь 2023&nbsp;г., в
              сравнении с аналогичной датой 2022&nbsp;г.)
            </h3>
            <p>
              По данным национальных статистических служб, за январь-декабрь 2023&nbsp;г. по
              сравнению с аналогичным периодом 2022&nbsp;г. взаимная торговля государств-членов ЕАЭС
              выросла на 5,1% в стоимостном выражении – с 83,3 до
              87,5&nbsp;млрд&nbsp;долл.&nbsp;США. Наибольший рост взаимного экспорта наблюдается для
              Армении (на 40,8%, с 2,56 до 3,61&nbsp;млрд&nbsp;долл&nbsp;США). Экспорт Беларуси в
              страны-члены Союза возрос на 10%, с 23,4 до 25,8 млрд долл. США, Казахстана – на 9,5%,
              с 10,1 до 11,1&nbsp;млрд&nbsp;долл.&nbsp;США. Экспорт Кыргызстана в ЕАЭС в стоимостном
              выражении сократился на 18,2% – с 1,51 до 1,23&nbsp;млрд&nbsp;долл.&nbsp;США. Экспорт
              России на рынки партнеров по ЕАЭС почти не изменился в сравнении с аналогичным
              периодом прошлого года: сокращение составило всего 1%, с 46,3 до
              45,9&nbsp;млрд&nbsp;долл.&nbsp;США.
            </p>
          </div>

          <h4 id="Макроэкономические_показатели">
            Макроэкономические показатели по странам{' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            и странам-наблюдателям за январь-декабрь 2023 г.
          </h4>
          <Table
            tableHead={[
              // [
              //   { title: '', colspan: 1 },
              //   { title: 'Страны-члены', colspan: 5 },
              //   { title: 'Страны-наблюдатели', colspan: 3 },
              // ],
              [
                'Показатель',
                { img: armSmall, title: 'Армения' },
                { img: belSmall, title: 'Беларусь' },
                { img: kazSmall, title: 'Казахстан' },
                { img: kyrSmall, title: 'Кыргызстан' },
                { img: rusSmall, title: 'Россия' },
                // { img: uzbSmall, title: 'Узбекистан' },
                // { img: molSmall, title: 'Молдова' },
              ],
            ]}
            tableBody={[
              [
                'Рост реального ВВП (предварительные данные)',
                '8.7%',
                '3.9%',
                '5.1%',
                '6.2%',
                '3.6%',
              ],
              // [
              //   'Прогнозы роста реального ВВП ВБ (от января 2023 г.)',
              //   '10.8%',
              //   '-6.2%',
              //   '3.0%',
              //   '5.5%',
              //   '-3.5%',
              // ],
              ['Промышленное производство', '28.9%', '7.1%', '4.6%', '7.8%', '6.6%'],
              ['Индекс потребительских цен', '0.2%', '2.2%', '2.6%', '1.2%', '2.0%'],
              [
                'Темпы прироста взаимной торговли с ЕАЭС',
                '40.8%',
                '10.0%',
                '9.5%',
                '-18.2%',
                '-1.0%',
              ],
            ]}
            width="100%"
            theadAlign="center"
            tbodyClass="first"
          />
          <p style={{ fontSize: `${14}px` }}>
            Примечания:
            <br />
            <br />
            1) рост реального ВВП: январь-декабрь 2023 г. к январю- декабрю 2022 г.
            <br />
            <br />
            2) промышленное производство: январь-февраль 2024 г. к январю-февралю 2023 г.
            <br />
            <br />
            3) ИПЦ: март 2024 г. к декабрю 2023 г.
            <br />
            <br />
            4) темпы прироста взаимной торговли с ЕАЭС: январь-декабрь 2023 г. к январю- декабрю
            2022 г.
          </p>
          <div className={styles.commentWrapper}>
            <Comment
              title="Подробно о макроэкономических показателях стран ЕАЭС"
              content={
                <span>
                  <p>
                    • По данным национальных статистических служб, за 2023 г. по сравнению с
                    аналогичным периодом 2022 г. реальный ВВП Армении вырос на 8,7%, Беларуси – на
                    3,9%, Казахстана – на 5,1%, Киргизии – на 6,2%, России – на 3,6%.
                  </p>

                  <p>
                    • В январе-феврале 2024 г. объемы промышленного производства выросли во всех
                    странах ЕАЭС. По темпам отраслевого роста среди стран-членов Союза лидирует
                    Армения (+28,9% по сравнению с аналогичным периодом предыдущего года).
                  </p>

                  <p>
                    • В марте текущего года наиболее высокий уровень потребительской инфляции в ЕАЭС
                    зафиксирован в Казахстане (2,6% к декабрю 2023 г.).
                  </p>

                  <p>
                    • В 2023 г. относительно предыдущего года темпы роста взаимной торговли возросли
                    для 3-х стран ЕАЭС – Армении, Беларуси и Казахстана. Наибольший рост
                    зафиксирован в Армении – на 40,8%.
                  </p>
                </span>
              }
            />
          </div>
          <h4 id="_4">О проекте</h4>
          <p style={{ marginBottom: `30px` }}>
            Экономический анализ интеграции в Евразийском экономическом союзе ({' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            ) проводится{' '}
            <a href="http://www.vavt.ru/imef">Институтом международной экономики и финансов</a> и
            <a href="http://www.vavt.ru/science/site/LSP6ED90B">
              {' '}
              Институтом развития интеграционных процессов
            </a>{' '}
            при поддержке{' '}
            <a href="http://www.vavt.ru/science/site/cda">
              {' '}
              Центра анализа данных Всероссийской академии внешней торговли Министерства
              экономического развития Российской Федерации
            </a>
            .
          </p>
        </>
      )}

      {process.env.REACT_APP_ROUTERS === 'open_routers' && (
        <>
          <h1 id="_1">Евразийская интеграция в цифрах: экономический мониторинг</h1>
          <h2 id="Макроэкономика">Макроэкономика</h2>
          <div className={styles.containerTwoBlocks}>
            <div className={styles.itemTwoBlocks}>
              <h3 id="Прирост_ВВП">
                Валовый внутренний продукт: (+3,8% за январь-декабрь 2023&nbsp;г., к аналогичному
                периоду 2022&nbsp;г.)
              </h3>

              <p style={{ marginBottom: `${20}px` }}>
                В реальном выражении прирост{' '}
                <Tooltip text="Валовый внутренний продукт">
                  <span>ВВП</span>
                </Tooltip>{' '}
                по итогу 2023 г. в Армении составил +8,7%, в Беларуси +3,9%, в Казахстане +5,1%, в
                Киргизии +6,2%, в России +3,6%. Рост совокупного{' '}
                <Tooltip text="Валовый внутренний продукт">
                  <span>ВВП</span>
                </Tooltip>{' '}
                <Tooltip text="Евразийский экономический союз">
                  <span>ЕАЭС</span>
                </Tooltip>{' '}
                за 12 месяцев 2023 г. по сравнению с аналогичным периодом предшествующего года
                составил +3,8%.
              </p>
            </div>

            <div className={styles.itemTwoBlocks}>
              <h3 id="Промышленное_производство">
                Промышленное производство: (+6,6% за январь-февраль 2024&nbsp;г., к аналогичному
                периоду 2023&nbsp;г.)
              </h3>
              <p>
                По данным{' '}
                <Tooltip text="Евразийская экономическая комиссия">
                  <span>ЕЭК</span>
                </Tooltip>{' '}
                , в январе – феврале 2024 г. по сравнению с аналогичным периодом предыдущего года
                промышленное производство увеличилось во всех странах-членах{' '}
                <Tooltip text="Евразийский экономический союз">
                  <span>ЕАЭС</span>
                </Tooltip>{' '}
                : в Армении – на 28,9%, в Беларуси – на 7,1%, в Казахстане – на 4,6%, в Кыргызстане
                – на 7,8%, в России – на 6,6%. В целом по Союзу прирост в отрасли составил 6,6%, при
                этом объемы выпуска продукции в обрабатывающем секторе возросли на 10,4%, а в
                горнодобывающей промышленности масштабы производства возросли на 1,5%.
              </p>
            </div>
          </div>
          <h2 id="Индекс_потребительских_цен">Индекс потребительских цен</h2>
          <div className={styles.itemOneBlock}>
            <h3 id="Индекс_потребительских_цен">
              Индекс потребительских цен: +2,0% (март 2024&nbsp;г. к декабрю 2023&nbsp;г.)
            </h3>
            <p>
              По итогу 2023 г. рост инфляции в{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              составил 7,5% по отношению к декабрю 2022&nbsp;г., в т.ч. средних цен на
              продовольственные и непродовольственные товары – 7,8% и 6,3%, соответственно. Цены на
              платные услуги увеличились на 8,7%.
            </p>
            <p>
              Потребительская инфляция в марте 2024&nbsp;г. по отношению к декабрю предшествующего
              года в целом по странам-членам Союза выросла на 2,0%, в том числе в Армении – на 0,2%,
              Беларуси – на 2,2%, в Казахстане – на 2,6%, в Киргизии – на 1,2%, в России – на 2,0%.{' '}
            </p>
          </div>
          <h2 id="Безработица">Безработица</h2>
          <div className={styles.itemOneBlock}>
            <h3 id="Безработица">
              Безработица: -13,0% (на конец февраля 2024&nbsp;г. в сравнении с аналогичной датой
              2023&nbsp;г.)
            </h3>
            <p>
              В конце февраля 2024 г. по сравнению с аналогичной датой 2023 г. численность
              безработных в целом по{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              уменьшилась на 13,0%, достигнув 827,5 тыс чел. В Армении сокращение составило 3,8%, в
              Беларуси – 27,1%, в Киргизии – 12,0%, в России – 26,4%. В Казахстане число безработных
              выросло на 12,6%.
            </p>
          </div>
          <h2 id="Финансовый_сектор">Финансовый сектор</h2>
          <div className={styles.containerTwoBlocks}>
            <div className={styles.itemTwoBlocks}>
              <h3 id="Дефицит_бюджета">
                Государственный бюджет: -$45,3&nbsp;млрд за январь-декабрь 2023 г. (-54,6&nbsp;млрд
                $ – значение за аналогичный период прошлого года; без учета данных по Беларуси)
              </h3>
              <p>
                В январе-декабре 2023&nbsp;г. госбюджет был исполнен с профицитом в Кыргызстане. В
                Армении дефицит бюджета увеличился на 0,1 млрд $ относительно значения за
                аналогичный период прошлого года, в Казахстане – на 1,6 млрд $, а в России
                зафиксировано сокращение бюджетного дефицита в объеме 10,6 млрд $ (данные по
                Беларуси для этого периода отсутствуют).
              </p>
            </div>
            <div className={styles.itemTwoBlocks}>
              <h3 id="Госдолг">
                Госдолг (без учета данных по Беларуси): -3,8% (-15,2&nbsp;млрд&nbsp;$) (на 1 января
                2024&nbsp;г. в сравнении с аналогичной датой прошлого года)
              </h3>
              <p>
                К началу 2024&nbsp;г. во всех странах-членах Союза, кроме России (данные по Беларуси
                за этот год отсутствуют), зафиксировано увеличение государственной задолженности,
                выраженный в иностранной валюте (долларе США). По состоянию на 1 января 2024&nbsp;г.
                относительно аналогичной даты предыдущего года госдолг в долларовом эквиваленте в
                Республике Армения вырос на 11,3%, составив 11,8 млрд $, в Республике Казахстан – на
                9,1% (до 59,9&nbsp;млрд&nbsp;$), в Кыргызской Республике – на 12,5% (до
                6,3&nbsp;млрд&nbsp;$). В Российской Федерации госдолг сократился на 6,8% (до
                302,3&nbsp;млрд&nbsp;$).
              </p>
            </div>
          </div>

          <h2 id="Взаимная_и_внешняя_торговля_товарами">Взаимная торговля</h2>
          <div className={styles.itemOneBlock}>
            <h3 id="Взаимная_и_внешняя_торговля_товарами">
              Взаимная торговля: +5,1% (+4,2&nbsp;млрд&nbsp;$) (за январь-декабрь 2023&nbsp;г., в
              сравнении с аналогичной датой 2022&nbsp;г.)
            </h3>
            <p>
              По данным национальных статистических служб, за январь-декабрь 2023&nbsp;г. по
              сравнению с аналогичным периодом 2022&nbsp;г. взаимная торговля государств-членов ЕАЭС
              выросла на 5,1% в стоимостном выражении – с 83,3 до
              87,5&nbsp;млрд&nbsp;долл.&nbsp;США. Наибольший рост взаимного экспорта наблюдается для
              Армении (на 40,8%, с 2,56 до 3,61&nbsp;млрд&nbsp;долл&nbsp;США). Экспорт Беларуси в
              страны-члены Союза возрос на 10%, с 23,4 до 25,8 млрд долл. США, Казахстана – на 9,5%,
              с 10,1 до 11,1&nbsp;млрд&nbsp;долл.&nbsp;США. Экспорт Кыргызстана в ЕАЭС в стоимостном
              выражении сократился на 18,2% – с 1,51 до 1,23&nbsp;млрд&nbsp;долл.&nbsp;США. Экспорт
              России на рынки партнеров по ЕАЭС почти не изменился в сравнении с аналогичным
              периодом прошлого года: сокращение составило всего 1%, с 46,3 до
              45,9&nbsp;млрд&nbsp;долл.&nbsp;США.
            </p>
          </div>

          <h4 id="Макроэкономические_показатели">
            Макроэкономические показатели по странам{' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            и странам-наблюдателям за январь-декабрь 2023 г.
          </h4>
          <Table
            tableHead={[
              // [
              //   { title: '', colspan: 1 },
              //   { title: 'Страны-члены', colspan: 5 },
              //   { title: 'Страны-наблюдатели', colspan: 3 },
              // ],
              [
                'Показатель',
                { img: armSmall, title: 'Армения' },
                { img: belSmall, title: 'Беларусь' },
                { img: kazSmall, title: 'Казахстан' },
                { img: kyrSmall, title: 'Кыргызстан' },
                { img: rusSmall, title: 'Россия' },
                // { img: uzbSmall, title: 'Узбекистан' },
                // { img: molSmall, title: 'Молдова' },
              ],
            ]}
            tableBody={[
              [
                'Рост реального ВВП (предварительные данные)',
                '8.7%',
                '3.9%',
                '5.1%',
                '6.2%',
                '3.6%',
              ],
              // [
              //   'Прогнозы роста реального ВВП ВБ (от января 2023 г.)',
              //   '10.8%',
              //   '-6.2%',
              //   '3.0%',
              //   '5.5%',
              //   '-3.5%',
              // ],
              ['Промышленное производство', '28.9%', '7.1%', '4.6%', '7.8%', '6.6%'],
              ['Индекс потребительских цен', '0.2%', '2.2%', '2.6%', '1.2%', '2.0%'],
              [
                'Темпы прироста взаимной торговли с ЕАЭС',
                '40.8%',
                '10.0%',
                '9.5%',
                '-18.2%',
                '-1.0%',
              ],
            ]}
            width="100%"
            theadAlign="center"
            tbodyClass="first"
          />
          <p style={{ fontSize: `${14}px` }}>
            Примечания:
            <br />
            <br />
            1) рост реального ВВП: январь-декабрь 2023 г. к январю- декабрю 2022 г.
            <br />
            <br />
            2) промышленное производство: январь-февраль 2024 г. к январю-февралю 2023 г.
            <br />
            <br />
            3) ИПЦ: март 2024 г. к декабрю 2023 г.
            <br />
            <br />
            4) темпы прироста взаимной торговли с ЕАЭС: январь-декабрь 2023 г. к январю- декабрю
            2022 г.
          </p>
          <div className={styles.commentWrapper}>
            <Comment
              title="Подробно о макроэкономических показателях стран ЕАЭС"
              content={
                <span>
                  <p>
                    • По данным национальных статистических служб, за 2023 г. по сравнению с
                    аналогичным периодом 2022 г. реальный ВВП Армении вырос на 8,7%, Беларуси – на
                    3,9%, Казахстана – на 5,1%, Киргизии – на 6,2%, России – на 3,6%.
                  </p>

                  <p>
                    • В январе-феврале 2024 г. объемы промышленного производства выросли во всех
                    странах ЕАЭС. По темпам отраслевого роста среди стран-членов Союза лидирует
                    Армения (+28,9% по сравнению с аналогичным периодом предыдущего года).
                  </p>

                  <p>
                    • В марте текущего года наиболее высокий уровень потребительской инфляции в ЕАЭС
                    зафиксирован в Казахстане (2,6% к декабрю 2023 г.).
                  </p>

                  <p>
                    • В 2023 г. относительно предыдущего года темпы роста взаимной торговли возросли
                    для 3-х стран ЕАЭС – Армении, Беларуси и Казахстана. Наибольший рост
                    зафиксирован в Армении – на 40,8%.
                  </p>
                </span>
              }
            />
          </div>
          <h4 id="_4">О проекте</h4>
          <p style={{ marginBottom: `30px` }}>
            Экономический анализ интеграции в Евразийском экономическом союзе ({' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            ) проводится{' '}
            <a href="http://www.vavt.ru/imef">Институтом международной экономики и финансов</a> и
            <a href="http://www.vavt.ru/science/site/LSP6ED90B">
              {' '}
              Институтом развития интеграционных процессов
            </a>{' '}
            при поддержке{' '}
            <a href="http://www.vavt.ru/science/site/cda">
              {' '}
              Центра анализа данных Всероссийской академии внешней торговли Министерства
              экономического развития Российской Федерации
            </a>
            .
          </p>
        </>
      )}
    </div>
  );
};

export default PublicPage;
